import React, { HTMLAttributes } from 'react'

import { Container, Logo } from './styles'
import logoImage from '../../assets/logo-mais-prev.png'

interface Props extends HTMLAttributes<HTMLDivElement> {
  color?: 'white' | 'green' | 'pink' | 'purple' | 'orange' | 'blue'
  gradientDirection?: 'left' | 'right'
}

const Header: React.FC<Props> = ({
  color = 'white',
  className,
  gradientDirection = 'left',
  children,
}) => {
  return (
    <Container
      className={className}
      color={color}
      gradientDirection={gradientDirection}
    >
      {/* <Logo>
        <img src={logoImage} alt="BRF Previdencia" />
      </Logo> */}
      <strong>{children}</strong>
      <Logo />
    </Container>
  )
}

export default Header
