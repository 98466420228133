import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
display:flex;
  flex-direction: column;
  margin:0 auto;
  align-items: center;
  height:100%;
  min-height:calc(100vh - 95px);
  max-width: 420px;
    padding: 20px 15px;
> small {
  text-align: center;
}
  >form + div {
    height: 25px;
    margin-top: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:20px;

    > span{
      flex: 1;
      font-size: 12px;
      margin-right: 15px;
    }
  }
  @media screen and (min-width: 766px){
    max-width:500px;

    >form + div {
      height: 30px;
      margin-top: 20px;

      margin-bottom:24px;

      > span{
        flex: 1;
        font-size: 14px;
        margin-right: 15px;
      }
    }
  }
`;

export const Content = styled.div`
position:relative;
display: flex;
width: 90vw;
background:#FFF;
box-shadow: 0px 12px 14px rgba(209,212,226,0.4);
border-radius: 4px;
padding: 15px;
margin-bottom:40px;
flex-direction: column;
align-items: center;
@media screen and (min-width: 766px){
    width: 70vw;
    padding: 16px;

    max-width: 550px;

  }

  @media screen and (min-width: 990px) {
    width: 45vw;
  }
`

export const BenefBox = styled.main`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);
  height: 80px;
  width: 100%;
  margin: 10px 0 10px;
  padding: 15px 25px 10px;

  p {
    font-size: 14px;
  }

  span {
    color: ${darken(0.25, '#BACB60')};
    cursor: pointer;
    text-decoration: underline;
  }

  > strong {
    font-size: 18px;
    font-weight: bold;
    color: ${props => props.theme.colors.text};
    margin-left: 15px;
    text-align: center;
  }

  div {
    position: relative;
    margin: 6px 0;
  }

  svg {
    position: absolute;
    width: 20px;
    height: 20px;

    top: 0;
    right: 5px;
    cursor: pointer;
    color: ${props => props.theme.colors.error};
    z-index: 999;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px 10px;
  }
`

export const ButtonReject = styled.button`

  border: 0px;
  background: unset;
  color: #E27272;
  font-size: 16px;
  font-weight: bold;
  padding:4px 6px;
  &:hover{
    cursor: pointer;
  }
  &:focus{
    text-decoration: underline;
  }
`

export const LogoContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;

/*
  @media screen and (min-width: 766px) {
    width: 500px;
  }*/

`

export const DropDown = styled.div`
  width: 100%;

  > div {
    margin-top: 3px;
    margin-bottom: 3px;
    // font-size: 20px;
    // font: 'Verdana';
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    > label {
      font-weight: bold;
      margin-bottom: 12px;
      font-size: 20px;
    }

    > select {
      font-size: 14px;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    > div {
      display: flex;
      flex-direction:row;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 26px;
    }
  }
`

export const RadioButton = styled.div`
  width: 100%;

  > label {
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 20px;
  }

  > div{
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 20px;
    font: 'Verdana';
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-direction:row;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 26px;
    }

  }
`


export const InfBenefBox = styled.div`
 width: 100%;
 margin-bottom: 20px;

  small {
    margin-bottom: 5px;
  }

  strong {
    margin-right: 5px;
  }

  > div {
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    padding: 5px;

    > div{
      width: 50%;
    }
  }
`


export const BtnVoltar = styled.button`
  border: none;
  background-color: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
  padding: 0px 3px 2px;
  color: ${({ theme }) => theme.colors.text};

  &:hover {

    border-bottom: 1px solid ${({ theme }) => theme.colors.text};

  }
`
