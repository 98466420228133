/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useRef } from 'react'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { FiCheck } from 'react-icons/fi'

import { useHistory } from 'react-router-dom'

import {
  Container,
  Content,
  RadioButton,
  LogoContent,
  FatcaContent,
  TitleContent,
  BtnVoltar,
  BtnContato,
} from './styles'

import Logo from '../../components/Logo'
import usePersistedState from '../../hooks/usePersistedState'

import Button from '../../components/Button'

import { UserDetails } from '../../utils/interfaces'

const FatcaInfo: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )

  const [ppe, setPPE] = useState('N')
  const [checkFacta1, setCheckFacta1] = useState(false)
  const [checkFacta2, setCheckFacta2] = useState(false)
  const [checkFacta3, setCheckFacta3] = useState(false)
  const [checkFacta4, setCheckFacta4] = useState(false)
  const [checkFacta5, setCheckFacta5] = useState(false)
  const [checkFacta6, setCheckFacta6] = useState(false)
  const [checkFactaR, setCheckFactaR] = useState(false)
  const [checkFactaN, setCheckFactaN] = useState(false)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  const handleSubmit = useCallback(async () => {
    const usperson =
      (checkFactaR || checkFactaN) &&
      (!checkFacta1 ||
        !checkFacta2 ||
        !checkFacta3 ||
        !checkFacta4 ||
        !checkFacta5 ||
        !checkFacta6)
        ? 'N'
        : 'S'

    setUserDetails({
      ...userDetails,
      ppe,
      usperson,
    })
    // history.push('/conclusion')
    window.location.href = '/conclusion'
  }, [
    checkFacta1,
    checkFacta2,
    checkFacta3,
    checkFacta4,
    checkFacta5,
    checkFacta6,
    checkFactaN,
    checkFactaR,
    ppe,
    setUserDetails,
    userDetails,
  ])

  return (
    <>
      <Container>
        <LogoContent>
          <div>
            <Logo />
          </div>
        </LogoContent>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContent>
              <strong>Pessoa politicamente exposta (PPE)?</strong>
            </TitleContent>
            <FatcaContent>
              <div className="msg">
                <span>
                  De acordo com a instrução MPS/SPC nº 26 de 01/09/2008, Pessoa
                  Politicamente Exposta é aquela que exerce ou exerceu nos
                  últimos 5 anos, no Brasil ou no exterior, cargo, emprego ou
                  função pública relevante. Também é considerada aquela que
                  tenha representantes, familiares e outras pessoas de seu
                  relacionamento próximo que exerçem ou tenham exercido nos
                  últimos 5 anos, no Brasil ou no exterior, cargo, emprego ou
                  função pública relevante.
                </span>
              </div>

              <RadioButton>
                <div>
                  <div>
                    <BtnContato
                      type="button"
                      isActive={ppe === 'S'}
                      onClick={() => setPPE('S')}
                    >
                      Sim
                    </BtnContato>
                  </div>
                  <div>
                    <BtnContato
                      type="button"
                      isActive={ppe === 'N'}
                      onClick={() => setPPE('N')}
                    >
                      Não
                    </BtnContato>
                  </div>
                </div>
              </RadioButton>
            </FatcaContent>

            {/* <RadioButton>
              <div>
                <div>
                  <input
                    type="radio"
                    name="tipo"
                    value="S"
                    checked={ppe === 'S'}
                    onChange={e => setPPE(e.target.value)}
                  />
                  <label>Sim</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="tipo"
                    value="N"
                    checked={ppe === 'N'}
                    onChange={e => setPPE(e.target.value)}
                  />
                  <label>Não</label>
                </div>
              </div>
            </RadioButton> */}
            <FatcaContent>
              <TitleContent>
                <strong>E-Financeira / FATCA</strong>
              </TitleContent>
              <br />
              <div className="msg">
                <span>
                  O preenchimento destas informações visa atender à Lei norte
                  americana FATCA - Foreing Account Tax Compliance Act (Ato de
                  conformidade Fiscal de Contas Estrangeiras), previsto no
                  decreto brasileiro nº 8.506 de 24 de agosto de 2015, que tem
                  como objetivo identificar contribuintes do imposto norte
                  americano a fim de combater a evasão fiscal dos Estados
                  Unidos.
                </span>
              </div>
              <br />
              <div id="Check">
                <div id="Check1">
                  <input
                    type="checkbox"
                    name="Check1"
                    placeholder="Check1"
                    disabled={checkFactaR || checkFactaN}
                    checked={checkFactaR === false && checkFacta1 === true}
                    onChange={e => setCheckFacta1(e.target.checked)}
                  />
                  <label>Sou cidadão ou residente dos EUA.</label>
                </div>
                <div id="Check2">
                  <input
                    type="checkbox"
                    name="Check2"
                    placeholder="Check2"
                    disabled={checkFactaR || checkFactaN}
                    checked={checkFactaR === false && checkFacta2 === true}
                    onChange={e => setCheckFacta2(e.target.checked)}
                  />
                  <label>
                    Possuo visto de permanência nso EUA, do tipo Green Card.
                  </label>
                </div>
                <div id="Check3">
                  <input
                    type="checkbox"
                    name="Check3"
                    placeholder="Check3"
                    disabled={checkFactaR || checkFactaN}
                    checked={checkFactaR === false && checkFacta3 === true}
                    onChange={e => setCheckFacta3(e.target.checked)}
                  />
                  <label>
                    Possuo endereço de residência ou telefone atual nos EUA.
                  </label>
                </div>
                <div id="Check4">
                  <input
                    type="checkbox"
                    name="Check4"
                    placeholder="Check4"
                    disabled={checkFactaR || checkFactaN}
                    checked={checkFactaR === false && checkFacta4 === true}
                    onChange={e => setCheckFacta4(e.target.checked)}
                  />
                  <label>
                    Possuo conta bancária em instituições financeiras nos EUA.
                  </label>
                </div>
                <div id="Check5">
                  <input
                    type="checkbox"
                    name="Check5"
                    placeholder="Check5"
                    disabled={checkFactaR || checkFactaN}
                    checked={checkFactaR === false && checkFacta5 === true}
                    onChange={e => setCheckFacta5(e.target.checked)}
                  />
                  <label>
                    Concedi procuração válida ou outras atribuições a uma pessoa
                    que tenha um endereço nos EUA.
                  </label>
                </div>
                <div id="Check6">
                  <input
                    type="checkbox"
                    name="Check6"
                    placeholder="Check6"
                    disabled={checkFactaR || checkFactaN}
                    checked={checkFactaR === false && checkFacta6 === true}
                    onChange={e => setCheckFacta6(e.target.checked)}
                  />
                  <label>
                    Possuo um endereço para correspondência com a característica
                    de &quot;aos cuidados de&quot; nos EUA como único endereço
                    disponível.
                  </label>
                </div>
                <div id="Check7">
                  <input
                    type="checkbox"
                    name="Check7"
                    placeholder="Check7"
                    disabled={checkFactaN}
                    checked={checkFactaN === false && checkFactaR === true}
                    onChange={e => setCheckFactaR(e.target.checked)}
                  />
                  <label>
                    Renunciei/abdiquei da cidadania, Green Card ou nacionalidade
                    dos EUA.
                  </label>
                </div>
                <div id="Check8">
                  <input
                    type="checkbox"
                    name="Check8"
                    placeholder="Check8"
                    onChange={e => setCheckFactaN(e.target.checked)}
                  />
                  <label> NÃO, nenhuma das alternativas anteriores.</label>
                </div>
              </div>
            </FatcaContent>
          </Form>
        </Content>
        <Button
          type="button"
          fontSize="normal"
          color="green"
          width="large"
          onClick={handleSubmit}
          disabled={
            !checkFacta1 &&
            !checkFacta2 &&
            !checkFacta3 &&
            !checkFacta4 &&
            !checkFacta5 &&
            !checkFacta6 &&
            !checkFactaR &&
            !checkFactaN
          }
        >
          <FiCheck size={45} />
          <span>Pronto! Concluir</span>
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default FatcaInfo
