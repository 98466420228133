/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
import React, { useRef, useCallback, useState } from 'react'
import { FiDollarSign, FiClock } from 'react-icons/fi'
import Collapse from '@material-ui/core/Collapse'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import {
  Container,
  Content,
  BoxValue,
  BtnVoltar,
  LogoContent,
  TitleContent,
  BoxValueContent,
  DivC,
  ButtonSimulationCalc,
} from './styles'
import Logo from '../../components/Logo'
import usePersistedState from '../../hooks/usePersistedState'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { formatValue } from '../../utils/formatValues'
import getValidationErrors from '../../utils/getValidationErrors'

import { UserDetails } from '../../utils/interfaces'

const PersonalInfos: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [salarioValue, setSalarioValue] = useState(userDetails.salario)
  const [vlrCtbBasica, setVlrCtbBasica] = useState(
    userDetails.contribuicaoBasica,
  )
  const [vlrCtbPatrocinadora, setVlrCtbPatrocinadora] = useState(
    userDetails.contribuicaoPatrocinadora,
  )
  const [vlrCtbTotal, setVlrCtbTotal] = useState(
    userDetails.contribuicaoBasica + userDetails.contribuicaoPatrocinadora,
  )
  const [txtPct, setTxtPct] = useState(
    userDetails.pctContribuicaoBasica > 0
      ? userDetails.pctContribuicaoBasica
      : 4,
  )
  const history = useHistory()

  const formRef = useRef<FormHandles>(null)

  const handleMudaContrib = useCallback((pct, sal) => {
    if (pct <= 4) {
      setVlrCtbBasica((sal * pct) / 100)
      setVlrCtbPatrocinadora((sal * pct) / 100)
      setVlrCtbTotal((sal * pct) / 100 + (sal * pct) / 100)
    } else {
      setVlrCtbBasica((sal * pct) / 100)
      setVlrCtbPatrocinadora(sal * 0.04)
      setVlrCtbTotal((sal * pct) / 100 + (sal * 0.4) / 100)
    }
    setTxtPct(pct)
  }, [])

  const minusContrib = useCallback(() => {
    handleMudaContrib(txtPct - 1, salarioValue)
  }, [handleMudaContrib, salarioValue, txtPct])
  const plusContrib = useCallback(() => {
    handleMudaContrib(txtPct + 1, salarioValue)
  }, [handleMudaContrib, salarioValue, txtPct])

  const handleCalculaContribuicao = useCallback(
    sal => {
      setVlrCtbBasica(0)
      setSalarioValue(sal)
      if (sal !== null && sal > 0) {
        if (txtPct <= 4) {
          setVlrCtbBasica((sal * txtPct) / 100)
          setVlrCtbPatrocinadora((sal * txtPct) / 100)
          setVlrCtbTotal((sal * txtPct) / 100 + (sal * txtPct) / 100)
        } else if (txtPct > 4) {
          setVlrCtbBasica((sal * txtPct) / 100)
          setVlrCtbPatrocinadora((sal * 0.04) / 100)
          setVlrCtbTotal((sal * txtPct) / 100 + (sal * 0.4) / 100)
        }
      } else {
        handleMudaContrib(4, sal)
      }
    },
    [handleMudaContrib, txtPct],
  )

  const mudarSalario = useCallback(
    valor => {
      const v = valor.replace(',', '').replaceAll('.', '')
      if (isNaN(v)) {
        setSalarioValue(salarioValue)
      } else {
        const m = Math.floor(v.length - 2)
        const a = `${v.substr(0, m)}.${v.substr(m)}`
        const f = parseFloat(a)
        setSalarioValue(f)
        handleCalculaContribuicao(f)
      }
    },
    [salarioValue, handleCalculaContribuicao],
  )

  const handleSubmit = useCallback(
    async (data: UserDetails) => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          years: Yup.number()
            .min(1, 'Tempo de contribuição mínimo: 1 ano')
            .max(100, 'Tempo de contribuição máximo: 100 anos')
            .required(
              'Informe o tempo de contribuição para realizar a simulação',
            )
            .typeError(
              'Informe o tempo de contribuição para realizar a simulação',
            ),
          salario: Yup.string()
            .test('', 'Salário inválido.', () => salarioValue <= 100000)
            .required('Campo obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        setUserDetails({
          ...userDetails,
          salario: salarioValue,
          contribuicaoBasica: vlrCtbBasica,
          contribuicaoPatrocinadora: vlrCtbPatrocinadora,
          pctContribuicaoBasica: txtPct,
          years: data.years,
        })

        // history.push('/taxation')
        // window.location.href = '/taxation'
        window.location.href = '/simulation'
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      salarioValue,
      userDetails,
      setUserDetails,
      txtPct,
      vlrCtbBasica,
      vlrCtbPatrocinadora,
    ],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  return (
    <Container>
      <LogoContent>
        <div>
          <Logo />
        </div>
      </LogoContent>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={userDetails}>
          <BoxValue>
            <BoxValueContent>
              <p>
                Será descontado mensalmente de sua folha de pagamento. A empresa
                também fará a contribuição no mesmo valor. Defina o % de
                desconto sobre o seu salário e simule sua contribuição mensal.
              </p>
            </BoxValueContent>
            <br />
            <DivC>
              <Input
                sizeBox="large"
                icon={FiClock}
                name="years"
                type="number"
                min={1}
                sufix="anos"
                placeholder="Por quantos anos deseja pagar sua previdência privada até começar a recebê-la?"
              />
              <Input
                // value={formatValue(salarioValue)}
                icon={FiDollarSign}
                name="salario"
                mask="currency"
                type="text"
                placeholder="Qual o seu salário R$?"
                onChange={e => mudarSalario(e.target.value)}
              />
            </DivC>
            <Collapse in={salarioValue > 1}>
              <div>
                <label>
                  Com qual percentual você quer contribuir (lembrando que o
                  máximo da patrocinadora é 4%)
                </label>

                <ButtonSimulationCalc
                  type="button"
                  disabled={txtPct <= 2}
                  isSelected={txtPct > 2}
                  onClick={minusContrib}
                >
                  -
                </ButtonSimulationCalc>
                <ButtonSimulationCalc type="button" isSelected>
                  {txtPct}
                </ButtonSimulationCalc>

                <ButtonSimulationCalc
                  type="button"
                  disabled={txtPct >= 12}
                  isSelected={txtPct < 12}
                  onClick={plusContrib}
                >
                  +
                </ButtonSimulationCalc>
              </div>
              <br />
              <div>
                <div>
                  <label>Valor da contribuição do Participante: </label>
                  <strong>{formatValue(vlrCtbBasica)}</strong>
                </div>
              </div>
              <br />

              <div>
                <label>Valor da contribuição da Patrocinadora: </label>
                <strong>{formatValue(vlrCtbPatrocinadora)}</strong>
              </div>
              <br />
              <div>
                <label>Valor total de contribuição ao Plano: </label>
                <strong>
                  {formatValue(vlrCtbBasica + vlrCtbPatrocinadora)}
                </strong>
                <br />
                <small>(participante + patrocinadora)</small>
              </div>
              <div />
              {txtPct === 12 ? (
                <>
                  <br />
                  <br />
                  <div>
                    <span>
                      <i>
                        Para contribuir acima de 12%, por favor entre em contato
                        com a Patrocinadora.
                      </i>
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
              <br />
            </Collapse>
          </BoxValue>
        </Form>
        <br />
        <br />
        <small>
          Está em dúvida? Não se preocupe, depois você poderá alterar este valor
          sempre que desejar.
        </small>
        <br />
        <br />

        <Button
          type="submit"
          color="green"
          onClick={handleClick}
          disabled={salarioValue < 1 || isNaN(salarioValue)}
        >
          Continuar
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Content>
    </Container>
  )
}

export default PersonalInfos
