
export default {
  title: 'dark',
  colors: {
    primary: '#4B6F81',
    secondary: '#2F4550',
    tertiary: '#1E2C33',

    background: '#182328',
    backgroundSecondary: '#121B1E',
    backgroundTertiary: '#0C1214',

    placeholder: '#F4F4F9',
    text: '#fff',
    textSecondary: '#E4E4F1',

    golden: '#F29602',

    blue: '#8493F4',
    blueSecondary: '#D1D4E2',

    error: '#fddede',
    errorText: '#C53030',


  },
}
/*
info: css`
    background: #ebf8ff;
    color: #3172b7;
  `,
  success: css`
    background: #e6fffa;
    color: #2e656a;
  `,
    error: css`
    background: #fddede;
    color: #c53030;
  `,
*/

/**
 *
 * backgrounds: {
 *  lightest: '#252131',
 *  lighter: '#201B2D'
 *  dark: '#191622',
 *  darker: '#15121E',
 *  darkest: '#13111B',
 *
 *
 * }
 */
