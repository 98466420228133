import styled from 'styled-components';


export const Container = styled.div`


  display:flex;
  flex-direction: column;
  // align-items: center;
  height: 100%;
  width: 100%;
  margin:0 auto;
  padding: 20px 15px;
  justify-content: space-evenly;

  >strong{
    text-align:center;
    font-size:20px;
    justify-content: center;
    align-items: center;
    // color: #04679F;
  }

  form {
    width:100%;
    padding: 0 30px;
    display:flex;
    flex-direction: column;
    align-items: center;

    > div{
      margin-top: 15px;
      border-color:#D2D2D2;
    }
    button {
      margin-top: 25px;
    }

  }
  margin:0 auto;

  // margin-top:5%;
  // padding-top:25px;

  max-width: 420px;

  @media screen and (min-width: 766px) {
    max-width: 500px;
  }

`;

export const LogoContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
/*
  @media screen and (min-width: 766px) {
    width: 500px;
  }*/
`

export const RadioButton = styled.div`
  background: initial;
  border-radius: 4px;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  width: 100%;

  position:relative;

  border: 1px solid #D2D2D2;
  color: ${props => props.theme.colors.placeholder};

  margin-bottom: 12px;
  transition: .2s;

  > div {
    margin-top: 3px;
    margin-bottom: 3px;
    //font-size: 20px;
    //font: 'Verdana';
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-direction:row;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      // margin-top: 12px;
      // margin-bottom: 26px;
      margin-left: 10px;
      margin-right: 10px;
    }

    input {
    // appearance: none;
    color: ${ props => props.theme.colors.text};
    flex: 0;
    text-align:  right;
    border: 0;
    background: transparent;
    margin-right: 5px;

    &::placeholder {
      color: ${ props => props.theme.colors.placeholder};
    }

    & + span{
      margin-right: 0px;
      margin-left: 4px;
    }
  }

    & + span{
      margin-right: 0px;
      margin-left: 4px;
    }
  }
  svg {
    margin-right: 12px;
  }
`

export const BtnVoltar = styled.button`
  border: none;
  background-color: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
  padding: 0px 3px 2px;
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

interface BtnContatoProps {
  isActive: boolean;
}
export const BtnContato = styled.button<BtnContatoProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background-color: ${props => props.isActive? '#3BD49A' : 'transparent'} ;
  color: ${props => props.isActive? '#fff' : '#000'} ;
  border-radius: 4px;

  &:focus {
    text-decoration: underline;
  }
`
