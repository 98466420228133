/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'
import { Base64 } from 'js-base64'
import { CircularProgress } from '@material-ui/core'
import { FiCheck, FiClock } from 'react-icons/fi'
import { Container, Content, LogoContent, BtnVoltar } from './styles'
import Logo from '../../components/Logo'
import api from '../../services/api'
import Button from '../../components/Button'
import usePersistedState from '../../hooks/usePersistedState'

import {
  UserData,
  UserDetails,
  ConfigData,
  Participant,
  ErroProps,
} from '../../utils/interfaces'
import ModalBox from '../../components/Modal'

const Conclusion: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [participants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [erroProps, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [aguarde, setAguarde] = useState(false)
  const [timer, setTimer] = useState(0)
  const [reSend, setReSend] = useState(true)

  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [warningText, setWarningText] = useState('')

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const history = useHistory()

  const handleConfirmaAdesao = useCallback(async () => {
    setAguarde(true)
    console.log(
      `{
        "token": "${configData.token}",
        "versao": "2",
        "nome": "${userData.name}",
        "cpf": "${userData.cpf.replaceAll('.', '').replace('-', '')}",
        "nascimento": "${userData.birthdate}",
        "email": "${userData.email}",
        "telefone": "55${userData.phone
          .replace('(', '')
          .replace(') ', '')
          .replace('-', '')}",
        "valor": "${userDetails.contribuicaoBasica}",
        "valorsuplementar": "",
        "politicamenteexposto": "${userDetails.ppe}",
        "usperson": "${userDetails.usperson}",
        "beneficiarios": [${participants.map(
          participant => `
          {"nome": "${participant.data.name}",
          "sexo": "M",
          "nascimento": "${participant.data.birthdate}",
          "cpf": "${participant.data.cpf
            .replaceAll('.', '')
            .replace('-', '')}"}`,
        )}]
        }`,
    )

    const parametros = Base64.encode(
      `{
        "token": "${configData.token}",
        "versao": "2",
        "nome": "${userData.name}",
        "cpf": "${userData.cpf.replaceAll('.', '').replace('-', '')}",
        "nascimento": "${userData.birthdate}",
        "email": "${userData.email}",
        "telefone": "55${userData.phone
          .replace('(', '')
          .replace(') ', '')
          .replace('-', '')}",
        "patrocinadora":"${userData.patrocinadora}",
        "valor": "${userDetails.contribuicaoBasica}",
        "valorsuplementar": "",
        "politicamenteexposto": "${userDetails.ppe}",
        "usperson": "${userDetails.usperson}",
        "beneficiarios": [${participants.map(
          participant => `
          {"nome": "${participant.data.name}",
          "sexo": "M",
          "nascimento": "${participant.data.birthdate}",
          "cpf": "${participant.data.cpf
            .replaceAll('.', '')
            .replace('-', '')}"}`,
        )}]
        }`,
    )

    const parametrosFinal = Base64.encode(parametros)

    await api
      .post(`wsAdesao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
      .then(() => {
        localStorage.removeItem('@MaisPatrocinado:userDetails')
        localStorage.removeItem('@MaisPatrocinado:codCliente')
        localStorage.removeItem('@MaisPatrocinado:acceptTerms')
        localStorage.removeItem('@MaisPatrocinado:participantsGroup')
        localStorage.removeItem('@MaisPatrocinado:token')
        localStorage.removeItem('@MaisPatrocinado:tipoCont')
        localStorage.removeItem('@MaisPatrocinado:totalRentability')
        history.push('/end')
      })
      .catch(res => {
        if (res.message === 'Request failed with status code 401') {
          alert('Tempo de sessão expirado')
          history.push('/register')
        } else if (res.message === 'Request failed with status code 406') {
          setWarningText(
            'Adesão não autorizada. Verifique se você já possui cadastro no plano.',
          )
          setIsModalOpen(true)
          setReSend(false)
          setTimer(20)
          setAguarde(false)
        } else if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 408') {
          alert('Tempo de sessão expirado')
          history.push('/register')
        } else {
          setReSend(false)
          setTimer(20)
          setAguarde(false)
          setWarningText('Ops, algo deu errado. Tente novamente mais tarde.')
          setIsModalOpen(true)
        }
      })

    // history.push('/end')
  }, [
    configData.token,
    history,
    participants,
    setErroProps,
    userData,
    userDetails,
  ])

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }
    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer])

  return (
    <Container>
      <LogoContent>
        <div>
          <Logo />
        </div>
      </LogoContent>
      <Content>
        <strong>Confirmação de Adesão</strong>
        <p>
          Eu {userData.name}, inscrito no CPF <b>{userData.cpf}</b>, declaro que
          ao clicar em <b>Confirmar Adesão</b> estou confirmando minha adesão ao{' '}
          <b> Plano Mais Previdência </b> e que as informação prestadas são
          verídicas.
        </p>
      </Content>
      <div>
        <span>
          Aceito os{' '}
          <a
            href="https://maisprevidencia.com.br/lgpd/"
            target="_blank"
            rel="noopener noreferrer"
          >
            termos de uso
          </a>
        </span>
        <Switch
          onChange={toggleAcceptTerms}
          checked={acceptTerms}
          checkedIcon={false}
          uncheckedIcon={false}
          height={22}
          width={44}
          handleDiameter={18}
          offColor="#EB6A9F"
          offHandleColor="#fff"
          onHandleColor="#fff"
          onColor="#31D19E"
          disabled={aguarde}
        />
      </div>
      <footer>
        {/*
        <p>
          Clicar no botão abaixo, finaliza o processo de adesão eletrônica.
        </p> */}
        <Button
          type="button"
          color="green"
          width="large"
          disabled={!acceptTerms || !reSend}
          // onClick={handleNewParticipant}
          onClick={handleConfirmaAdesao}
        >
          {!aguarde ? (
            !reSend ? (
              <>
                <FiClock size={45} />
                <span>
                  Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
                </span>
              </>
            ) : (
              <>
                <FiCheck size={45} />
                <span>Confirmar adesão</span>
              </>
            )
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </footer>
      <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
        <p>{warningText}</p>
      </ModalBox>
    </Container>
  )
}

export default Conclusion
