import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px;
  justify-content: space-evenly;
  min-height: 100vh;
  height: 100%;
  margin:0 auto;

  max-width: 420px;

  > footer {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 150px;

    p {
      font-size: 20px;
      // color: #04679F;
      text-align: center;
      margin-bottom: 12px;
      align-items: center;
    }
  }

  @media screen and (min-width:766px) {
    max-width: 500px;
    > footer {
      p {
        margin-bottom: 15px;
      }
    }
  }
`;

export const LogoContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;

/*
  @media screen and (min-width: 766px) {
    width: 500px;
  }*/

`

export const Content = styled.main`
  display:flex;
  flex-direction: column;
  align-items: center;

  strong {
    // color: #04679F;
    font-size: 16px;
    text-align: center;
  }

  > img {
    height:185px;
    width: auto;
    margin: 30px 0px;
  }

  > p {
    font-size: 16px;
    text-align: center;
    line-height:18px;
  }

  > div {
    margin:42px 0 38px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    height: 95px;

    >small {
      font-weight: bold;
      font-size: 12px;
      color: #8a8a8a;
      text-align: center;
      display: inline-block;
    }

    > div {
      width: 150px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        padding: 0;
        margin:0;
        img {
          height: 42px;
        }
      }
    }
  }

  @media screen and (min-width:766px) {
    strong {
      font-size: 18px;
    }
    > img {
      height: 200px;
    }
    > p {
      font-size: 17px;
      line-height:19px;
    }
    > div {
      height: 120px;

      > small {
        font-size: 14px;

      }
      > div {
        width: 180px;
        a {
          img {
            height: 50px;
          }
        }
      }
    }
  }
`
