import React from 'react'

import { SliceTooltipProps } from '@nivo/line'
import { Container } from './styles'

const Tooltip: React.FC<SliceTooltipProps> = ({ slice }) => {
  return (
    <Container>
      {slice.points.map(point => (
        <div key={point.id}>
          <span>
            {slice.points.length > 1 && (
              <>
                <strong style={{ backgroundColor: point.serieColor }}>
                  {point.serieId}
                </strong>
                :
              </>
            )}
            {point.data.yFormatted}
          </span>
        </div>
      ))}
    </Container>
  )
}

export default Tooltip
