import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: ${props => props.theme.colors.background};

    -webkit-font-smoothing: antialiased;
    color: ${props => props.theme.colors.text};
  }

  body, input, button, textarea {
    font-family: 'Verdana', 'Roboto', sans-serif;
    font-size: 16px;
  }


  button {
    cursor: pointer;
    color: ${props => props.theme.colors.text};
  }

  .react-modal-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: rgba(0, 0, 0, .55);

  @media screen and (max-width: 766px) {
    justify-content: flex-end;
  }
}

.react-modal-content {
  position: relative;
  padding: 35px 24px;
  width: 500px;

  background: #F4F6F5;
  border-radius: 4px;
  border-color: ${props => props.theme.colors.backgroundTertiary};

  text-align: center;

  > svg {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    color: ${props => props.theme.colors.error};
    cursor: pointer;
  }

  > h3 {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 20px;
    color: #F5A623;
  }

  > p {
    font-size: 16px;
    font-weight: normal;
    color: ${props => props.theme.colors.text};
  }

  strong {
    font-size: 20px;
    font-weight: 550;
    color: ${props => props.theme.colors.text};
  }

  div {
    margin: 15px 0;

    h3 {
      font-size: 18px;
      font-weight: 550;
    }

    p {
      font-size: 16px;
    }
  }

  small {
    font-size: 12px;
    width: 90%;
    color: ${props => props.theme.colors.placeholder};
  }

  @media screen and (max-width: 766px) {
    width: 100%;
  }
}
`
