import axios from 'axios'

const api = axios.create({
  // baseURL: 'http://192.1.0.52:8049/webrunstudio/'
  // baseURL: 'https://www.aspprev.net.br:8447/'
    baseURL: 'https://api.aspprev.tec.br/'

})

export default api
