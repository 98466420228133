/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-alert */
import React, { useCallback, useRef, useState, useEffect } from 'react'

import {
  FiUser,
  FiPhone,
  FiCalendar,
  FiSmartphone,
  FiMail,
} from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import Collapse from '@material-ui/core/Collapse'

import * as Yup from 'yup'

import { useHistory } from 'react-router-dom'

import { Base64 } from 'js-base64'
import moment from 'moment'
import api from '../../services/api'

import getValidationErrors from '../../utils/getValidationErrors'

import Input from '../../components/Input'
import InputHidden from '../../components/InputHidden'

import Logo from '../../components/Logo'
import Button from '../../components/Button'
import usePersistedState from '../../hooks/usePersistedState'

import { Container, LogoContent, RadioButton, BtnContato } from './styles'
import Header from '../../components/Header'
import validaCPF from '../../utils/validaCPF'

import { UserData, ConfigData, ErroProps } from '../../utils/interfaces'
import ModalBox from '../../components/Modal'

const Register: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [erroProps, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )

  const [tipoContato, setTipoContato] = useState('S')
  const [tel, setTel] = useState(userData.phone ? userData.phone : '')
  const [mail, setMail] = useState(userData.email ? userData.email : '')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [warningText, setWarningText] = useState('')

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleSubmit = useCallback(
    async (data: UserData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          cpf: Yup.string()
            .required('CPF obrigatório')
            .test(
              '',
              'CPF inválido',
              () =>
                validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) ||
                data.cpf === '',
            ),
          admission: Yup.string()
            .required('Data de admissão obrigatória')
            .test(
              '',
              'A data de admissão não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(data.admission.split('/').reverse().join('-')) ||
                data.admission === '',
            )
            .test(
              '',
              'Data de admissão inválida',
              () =>
                moment(
                  data.admission.split('/').reverse().join('-'),
                ).isValid() || data.admission === '',
            ),
          tipContato: Yup.string().required(),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .when('tipContato', {
              is: 'E',
              then: Yup.string().required('E-mail obrigatório'),
            }),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .min(14, 'Telefone inválido')
            .when('tipContato', {
              is: 'S',
              then: Yup.string().required('Celular obrigatório'),
            }),
        })

        await schema.validate(data, { abortEarly: false })

        const aa = data.admission

        const dia = aa.split('/')[0]
        const mes = aa.split('/')[1]
        const ano = aa.split('/')[2]

        const dataForm = `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`

        const parametros = Base64.encode(
          `{"versao":"${configData.tipo}",
            "plano":"${configData.plano}",
            "email":"${data.email === undefined ? '' : data.email}",
            "telefone":"${
              data.phone === undefined
                ? ''
                : `55${data.phone
                    .replace('(', '')
                    .replace(') ', '')
                    .replace('-', '')}`
            }",
            "cpf":"${data.cpf.replaceAll('.', '').replace('-', '')}",
            "admissao":"${dataForm}",
            "cliente":"${configData.codCliente}",
            "envio":"${tipoContato}"}`,
        )
        /*
          console.log(
            `{"versao":"2",
          "email":"${data.emailP === undefined ? '' : data.emailP}",
          "telefone":"${
            data.phoneCel === undefined
              ? ''
              : `55${data.phoneCel
                  .replace('(', '')
                  .replace(') ', '')
                  .replace('-', '')}`
          }",
          "cpf":"${data.cpf.replaceAll('.', '').replace('-', '')}",
          "admissao":"${dataForm}",
          "cliente":"1001","envio":"${tipoContato}"}`,
          ) */

        const parametrosFinal = Base64.encode(parametros)
        await api
          .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => {
            setConfigData({
              ...configData,
              token: res.data.token,
              tipoContato,
            })
            setUserData({
              ...userData,
              cpf: data.cpf,
              admission: dataForm,
              name: '',
              emailCorp: '',
              email: data.email,
              phoneFix: '',
              phone: data.phone,
            })
            history.push('/register/confirm-sms')
          })
          .catch(res => {
            if (res.message === 'Network Error') {
              setErroProps({
                title: res.message,
                description: 'Falha na conexão como servidor',
              })
              history.push('/erro')
            } else if (res.message === 'Request failed with status code 500') {
              setErroProps({
                title: 'Erro interno no servidor',
                description: res.message,
              })
              history.push('/erro')
            } else if (res.message === 'Request failed with status code 404') {
              setIsModalOpen(true)
              setWarningText(
                'Não localizamos os seus dados na base cadastral. Por favor, confira as informações digitadas ou entre em contato com a área de atendimento.',
              )
            } else if (res.message === 'Request failed with status code 401') {
              setIsModalOpen(true)
              setWarningText('Você já é participante.')
            } else {
              setIsModalOpen(true)
              setWarningText(
                'Ops, algo deu errado. Tente novamente mais tarde.',
              )
            }
          })

        setConfigData({
          ...configData,
          tipoContato,
        })
        setUserData({
          ...userData,
          cpf: data.cpf,
          admission: dataForm,
          name: '',
          emailCorp: '',
          email: data.email,
          phoneFix: '',
          phone: data.phone,
        })

        // history.push('/register/confirm-sms')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      configData,
      history,
      setConfigData,
      setErroProps,
      setUserData,
      tipoContato,
      userData,
    ],
  )

  const [teste, setTeste] = useState(
    userData.admission
      ? userData.admission.split('-').reverse().join('-').replaceAll('-', '/')
      : '',
  )
  const handleOnKeyDown = useCallback(e => {
    if (e.which === 8) {
      let val = e.target.value
      if (val.length === 3 || val.length === 6) {
        val = val.slice(0, val.length - 1)
        setTeste(val)
      }
    }
  }, [])
  const handleOnChange = useCallback(
    e => {
      let val = e.target.value
      if (val.length === 2) {
        val += '/'
      } else if (val.length === 5) {
        val += '/'
      } else if (val.length > 10) {
        val = teste
      }
      setTeste(val)
    },
    [teste, setTeste],
  )

  return (
    <>
      <Container>
        <LogoContent>
          <div>
            <Logo />
          </div>
        </LogoContent>
        <strong>Informe seus dados para prosseguir.</strong>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            cpf: userData.cpf,
            admission: userData.admission,
            phone: userData.phone,
            email: userData.email,
          }}
        >
          <Input
            placeholder="CPF"
            name="cpf"
            icon={MdSecurity}
            type="tel"
            mask="cpf"
          />
          <Input
            icon={FiCalendar}
            name="admission"
            // type="date"
            value={teste}
            placeholder="Data de admissão"
            min="1930-01-01"
            max={new Date().toISOString().split('T')[0]}
            onChange={e => handleOnChange(e)}
            onKeyDown={e => handleOnKeyDown(e)}
          />
          <RadioButton>
            <label>Enviar código por: </label>
            <div>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'S'}
                  onClick={() => setTipoContato('S')}
                >
                  SMS
                </BtnContato>
              </div>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'E'}
                  onClick={() => setTipoContato('E')}
                >
                  E-mail
                </BtnContato>
              </div>
            </div>
          </RadioButton>
          {tipoContato === 'S' ? (
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Celular com DDD"
              icon={FiSmartphone}
              value={tel}
              onChange={e => setTel(e.target.value)}
            />
          ) : (
            <Input
              icon={FiMail}
              name="email"
              id="email"
              type="email"
              placeholder="E-mail"
              maxLength={150}
              value={mail}
              onChange={e => setMail(e.target.value)}
            />
          )}
          <InputHidden name="tipContato" value={tipoContato} type="hidden" />
          <Button type="submit" color="green">
            Confirmar
          </Button>
        </Form>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <p>{warningText}</p>
        </ModalBox>
      </Container>
    </>
  )
}

export default Register
