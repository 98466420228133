export default {
  title: 'light',
  colors: {
    primary: '#FDA10D',
    secondary: '#EE9608',
    tertiary: '#DE8A02',

    background: '#F7F8FE',
    backgroundSecondary: '#EAEBEE',
    backgroundTertiary: '#D9DADD',

    placeholder: '#666360',
    text: '#333',
    textSecondary: '#F5F5F5',

    golden: '#B67102', /*'#F29602',*/

    blue: '#8493F4',
    blueSecondary: '#D1D4E2',

    error: '#fddede',
    errorText: '#C53030',
  },
}


