/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useRef, useCallback, useEffect, useState } from 'react'
import { Form } from '@unform/web'
import { FiCheck } from 'react-icons/fi'

import { useHistory } from 'react-router-dom'

import { FormHandles, Scope } from '@unform/core'
import _cloneDeep from 'lodash/cloneDeep'
import usePersistedState from '../../hooks/usePersistedState'

import Button from '../../components/Button'
import InputHidden from '../../components/InputHidden'

import {
  Container,
  Content,
  ButtonReject,
  LogoContent,
  InfBenefBox,
  BenefBox,
} from './styles'
import Logo from '../../components/Logo'

import { Participant } from '../../utils/interfaces'

interface CopiaDependent {
  data: {
    name: string
    cpf: string
    birthdate: string
  }
  details: {
    tipVinculo: string
    tipoBen: string
    grauParentesco: string
    dcrGrauParentesco: string
    proporcao: number
  }
}

const ParticipantsList: React.FC = () => {
  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const dependentsListCopy: CopiaDependent[] = _cloneDeep(participants)
  const [partLength, setPartLength] = useState(participants.length)
  const arr = participants.map(participant => participant.details.proporcao)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  const handleSubmit = useCallback(() => {
    history.push('/fatca')
  }, [history])
  const handleClickSubmit = useCallback(() => formRef.current?.submitForm(), [])

  let soma = 0
  for (let i = 0; i < arr.length; i += 1) {
    soma += arr[i]
  }
  const [totalProporcao, setTotalProporcao] = useState(soma)

  const teste = useCallback(
    async arrAux => {
      await setParticipants([...arrAux])
      setTotalProporcao(100)
    },
    [setParticipants],
  )

  const handleProporcionalizar = useCallback(async () => {
    const value = (100 / partLength).toFixed(2)

    const contador = partLength

    for (let i = 0; i < contador; i += 1) {
      dependentsListCopy[i].details.proporcao = parseFloat(value)
    }
    teste(dependentsListCopy)
  }, [dependentsListCopy, partLength, teste])

  useEffect(() => {
    setTotalProporcao(soma)
  }, [soma])

  const handleRemove = useCallback(
    id => {
      setParticipants(() =>
        participants.filter((participant, idx) => idx !== id),
      )
    },
    [participants, setParticipants],
  )

  return (
    <>
      <Container>
        <LogoContent>
          <div>
            <Logo />
          </div>
        </LogoContent>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            parts: participants.map((participant, idx) => ({
              name: participant.data.name,
              cpf: participant.data.cpf,
              // email: participant.details.email,
              tipoBen: participant.details.tipoBen,
              tipVinculo: participant.details.tipVinculo,
              birthdate: participant.data.birthdate,
              proporcao: participant.details.proporcao,

              // bestDayPayment: participant.details.bestDayPayment,
              // contribution: participant.details.contribution,
              // paymentType: participant.details.paymentType,
              id: idx,
            })),
          }}
        >
          {participants.map((participant, idx) => (
            <Content key={Math.random()}>
              <Scope path={`parts[${idx}]`}>
                <InputHidden name="addNew" type="hidden" />
                <InputHidden name="id" type="hidden" />
                <InputHidden name="contribution" type="hidden" />
                <InputHidden name="years" type="hidden" />
                <InfBenefBox>
                  <div>
                    <small>
                      {`${idx + 1} - Beneficiário ${
                        participant.details.tipoBen === 'L'
                          ? 'Legal'
                          : participant.details.tipoBen === 'I'
                          ? 'Indicado'
                          : 'Legal/Indicado'
                      }`}
                    </small>
                  </div>
                  <div>
                    <strong>Nome: </strong>
                    <label>{participant.data.name}</label>
                  </div>
                  <div>
                    <div>
                      <strong>Nascimento: </strong>
                      <label>{participant.data.birthdate}</label>
                    </div>
                    <div>
                      <strong>CPF: </strong>
                      <label>{participant.data.cpf}</label>
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong>Proporção: </strong>
                      <label>{participant.details.proporcao}%</label>
                    </div>
                    {participant.details.tipVinculo !== '' ? (
                      <div>
                        <strong>Vínculo: </strong>
                        <label>
                          {participant.details.tipVinculo === 'C'
                            ? 'Cônjuge'
                            : 'Filho/enteado'}
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </InfBenefBox>
                {/* <Input
                  placeholder="Tipo de benficiário"
                  name="tipoBen"
                  icon={FiUser}
                />
                {participant.details.tipVinculo !== '' ? (
                  <Input
                    placeholder="Vínculo"
                    name="tipVinculo"
                    icon={FiUser}
                  />
                ) : (
                  <></>
                )}
                <Input placeholder="Nome completo" name="name" icon={FiUser} />
                <Input
                  icon={FiCalendar}
                  name="birthdate"
                  type="date"
                  placeholder="Data de nascimento"
                  min="1930-01-01"
                  max={new Date().toISOString().split('T')[0]}
                />
                <Input
                  placeholder="CPF"
                  name="cpf"
                  icon={MdSecurity}
                  type="tel"
                  mask="cpf"
                  required
                />
                <Input placeholder="Proporção" name="proporcao" icon={FiUser} /> */}
                {/* <InputSelect
                  name="paymentType"
                  options={[
                    { label: 'Boleto', value: 'boleto' },
                    { label: 'Cartão de crédito', value: 'credit' },
                    { label: 'Débito em conta', value: 'debito'  },
                  ]}
                  defaultValue={{
                    value: participant.details.paymentType,
                    label: getLabel(participant.details.paymentType),
                  }}
                  placeholder="Forma de pagamento"
                  icon={BiDollar}
                />
                <Input
                  type="number"
                  prefix="R$"
                  step={0.01}
                  name="contribution"
                  icon={FaRegMoneyBillAlt}
                  placeholder="Aporte mensal"
                /> */}
              </Scope>

              <ButtonReject type="button" onClick={() => handleRemove(idx)}>
                Remover
              </ButtonReject>
            </Content>
          ))}
        </Form>
        {partLength === 0 ? (
          <Button
            type="button"
            fontSize="normal"
            color="white"
            width="large"
            onClick={() => history.push('/new-participant')}
          >
            Adicionar beneficiário
          </Button>
        ) : (
          <></>
        )}

        {totalProporcao !== 100 && partLength > 0 ? (
          <>
            <BenefBox>
              <p>
                Você ainda não distribuiu 100% da proporção entre seus
                beneficiários. Adicione mais beneficiários ou{' '}
                <span onClick={() => handleProporcionalizar()}>
                  clique aqui
                </span>{' '}
                para distribuir igualmente entre todos os informados.
              </p>
            </BenefBox>
            <Button
              type="button"
              fontSize="normal"
              color="white"
              width="large"
              onClick={() => history.push('/new-participant')}
            >
              Adicionar mais um beneficiário
            </Button>
          </>
        ) : (
          <>
            <Button
              type="button"
              fontSize="normal"
              color="green"
              width="large"
              onClick={handleClickSubmit}
            >
              {partLength === 0 ? (
                <span>Não possuo beneficiários</span>
              ) : (
                <>
                  <FiCheck size={45} />
                  <span>Pronto! Avançar</span>
                </>
              )}
            </Button>
          </>
        )}

        {totalProporcao === 100 ? (
          <Button
            type="button"
            fontSize="small"
            color="white"
            width="small"
            onClick={() => history.push('/new-participant')}
          >
            Adicionar mais um beneficiário
          </Button>
        ) : (
          <></>
        )}
      </Container>
    </>
  )
}

export default ParticipantsList
