import React, { useState, useCallback, useEffect } from 'react'
import Switch from 'react-switch'
import { useHistory } from 'react-router-dom'

import { FiArrowRightCircle } from 'react-icons/fi'
import Button from '../../components/Button'
import Logo from '../../components/Logo'
import Header from '../../components/Header'
import api from '../../services/api'

import {
  Container,
  DivContent,
  Content,
  Footer,
  BtnVoltar,
  LogoContent,
} from './styles'

import Dots from './components/Dots'

import welcomeWallet from '../../assets/welcome-wallet.svg'
import ilustracao from '../../assets/Ilustracao_tela2.png'
import welcomeHandPork from '../../assets/welcome-hand-pork.svg'
import welcomeEnd from '../../assets/welcome-end.svg'
import usePersistedState from '../../hooks/usePersistedState'

const BemVindo: React.FC = () => {
  const [active, setActive] = useState(0)
  const [quantity, setQuantity] = useState(3)
  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)

  const history = useHistory()

  const handleSetProximo = useCallback(() => {
    const newActive = active + 1
    if (newActive < quantity) {
      setActive(newActive)
    }
  }, [active, quantity])

  const handleSetAnterior = useCallback(() => {
    const newActive = active - 1
    if (newActive >= 0) {
      setActive(newActive)
    }
  }, [active])

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  const handleStart = useCallback(() => {
    // if (acceptTerms) {
    //   history.push('/register')
    // } else {
    //   console.warn('is necessary accept the terms to continue.')
    // }
    history.push('/register')
  }, [history])

  useEffect(() => {
    localStorage.removeItem('@MaisPatrocinado:token')
    localStorage.removeItem('@MaisPatrocinado:tipoCont')
    localStorage.removeItem('@MaisPatrocinado:userDetails')
    localStorage.removeItem('@MaisPatrocinado:receiveTypeSelected')
    localStorage.removeItem('@MaisPatrocinado:totalRentability')
    localStorage.removeItem('@MaisPatrocinado:TimeValueYears')
    localStorage.removeItem('@MaisPatrocinado:PercentualValuePercent')
    localStorage.removeItem('@MaisPatrocinado:authorizeTitularPayment')
    localStorage.removeItem('@MaisPatrocinado:userData')
    localStorage.removeItem('@MaisPatrocinado:participantsGroup')
  }, [])

  return (
    <Container>
      <LogoContent>
        <div>
          <Logo />
        </div>
      </LogoContent>
      <DivContent>
        <Content isActive={active === 0}>
          <strong>Olá, você é colaborador?</strong>
          <br />
          <span>
            Sim?! Então comece agora mesmo a planejar o seu futuro fazendo sua
            adesão ao Plano Mais Previdência.
          </span>
          {/* <img src={ilustracao} alt="Carteira" height="150px" /> */}
          <br />
          <br />
          <br />
          <Button
            color="green"
            onClick={handleStart}
            width="large"
            // disabled={!acceptTerms}
            // isVisible={active + 1 === quantity}
          >
            Clique aqui e faça sua adesão!
            <FiArrowRightCircle size={45} />
          </Button>
          <br />
          <br />
          {/* <strong>Quer ter uma aposentadoria de verdade?</strong> */}
          <span>
            O Plano Mais Previdência é um plano de previdência oferecido a todos
            os os colaboradores! O funcionário contribui com uma parte e a
            empresa o mesmo valor, limitado as regras regulamentares. O objetivo
            principal é formar reserva de poupança e garantir maior segurança
            financeira para o seu futuro! Um complemento de renda aos valores
            que serão recebidos pelo INSS.
          </span>
          <br />
          {/* <div>
            <span>Gostou?! </span>
            <BtnVoltar type="button" onClick={handleStart}>
              Clique aqui e faça agora mesmo a sua adesão!
            </BtnVoltar>
          </div> */}
        </Content>
        {/*
        <Content isActive={active === 1}>
          <img src={welcomeHandPork} alt="Carteira" />
          <strong>
            Investir em previdência privada te proporciona dedução de até 12% da
            base de cálculo do seu Imposto de Renda anual.
          </strong>
          <span />
          <div />
        </Content>

        <Content isActive={active === 2}>
          <img src={welcomeEnd} alt="Carteira" />
          <strong>Aqui tudo é simples e direto!</strong>
          <span>
            Surgiu algo inesperado? <br />
            Te damos a opção de fazer resgates parciais a partir do 5º ano.
          </span>
          <div>
            <span> Aceito os termos de privacidade</span>
            <Switch
              onChange={toggleAcceptTerms}
              checked={acceptTerms}
              checkedIcon={false}
              uncheckedIcon={false}
              height={22}
              width={44}
              handleDiameter={18}
              offColor="#EB6A9F"
              offHandleColor="#fff"
              onHandleColor="#fff"
              onColor="#31D19E"
            />
          </div>
        </Content> */}
      </DivContent>
      {/* <Footer>
        <Dots quantity={quantity} active={active} />
        <Button
          color="green"
          onClick={handleSetProximo}
          isVisible={active + 1 !== quantity}
        >
          Próximo
        </Button>
        <Button
          color="green"
          onClick={handleStart}
          disabled={!acceptTerms}
          // isVisible={active + 1 === quantity}
        >
          Começar
        </Button>
        {active > 0 && (
          <BtnVoltar type="button" onClick={handleSetAnterior}>
            &lt; Anterior
          </BtnVoltar>
        )}
      </Footer> */}
    </Container>
  )
}

export default BemVindo
