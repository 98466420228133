import styled, { css } from 'styled-components'

import Tooltip from '../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
  sizeBox?: 'small' | 'large'
  hasSufix?: boolean
}

export const Container = styled.div<ContainerProps>`
  background: initial;
  border-radius: 4px;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  width: 100%;

  position:relative;

  border: 1px solid #D2D2D2;
  color: ${props => props.theme.colors.placeholder};

  margin-bottom: 12px;
  transition: .2s;


  ${({ sizeBox }) => (sizeBox && sizeBox === 'large') && css`
    height: 70px;
    > label {
      font-size: 14px;
    }`}


  ${props =>
    props.isErrored &&
    css`
      border-color: ${props => props.theme.colors.errorText};
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: ${props => props.theme.colors.blue};
      color: ${props => props.theme.colors.blue};
    `}


  ${props =>
    props.isFilled &&
    css`
      color: ${props => props.theme.colors.blueSecondary};
    `}

  > label {
    position: absolute;
    top: 16px;
    left: 12px;
    padding-right:12px;

    color: ${({ theme }) => theme.colors.text} ;

    transition: .2s;
    pointer-events: none;
  }
  ${props =>
    props.isErrored && (props.sizeBox && props.sizeBox === 'large') &&
    css`
      > label {
        font-size:13px;
        padding-right:30px;
      }
    `}

  ${props => (props.isFilled || props.isFocused) && css`
    padding-top:24px;
    ${props.sizeBox === 'large' && css`
      padding-top:33px;
    `}
    > label {
      transform: translateY(-12px);
      font-size: 12px;
      letter-spacing: 0.1em;
    }
  `}



  span {
    color: ${ props => props.theme.colors.text};

    display: inline-block;

      margin-right: 4px;

  }

  svg {
    margin-right: 12px;
  }
  input {
    appearance: none;
    color: ${ props => props.theme.colors.text};
    flex: ${props => !props.hasSufix ? 1 : 0};
    text-align: ${props => !props.hasSufix ? 'left' : 'right'};
    border: 0;
    background: transparent;

    &::placeholder {
      color: ${ props => props.theme.colors.placeholder};
    }


    & + span{
      margin-right: 0px;
      margin-left: 4px;
    }

  }
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;

    }
    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
      svg {
        margin-right: 12px;
      }
    }

`

export const Error = styled(Tooltip)`
height: 20px;
margin-left: 12px;

svg {
  margin: 0%;
}

span {
  background: ${ props => props.theme.colors.errorText};
  color: #fff;

    &::before {
    border-color: ${ props => props.theme.colors.errorText} transparent;
  }
}
`
