/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Switch from 'react-switch'
import { FiMail, FiUser, FiCalendar, FiCheck, FiPercent } from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { BiDollar } from 'react-icons/bi'

import * as Yup from 'yup'

import { useHistory, useLocation, Link } from 'react-router-dom'

import moment from 'moment'
import getValidationErrors from '../../utils/getValidationErrors'
import {
  Container,
  Content,
  BoxValue,
  Divider,
  DivTerms,
  LogoContent,
  RadioButton,
  DropDown,
  BtnVoltar,
  BtnContato,
} from './styles'

import Header from '../../components/Header'
import usePersistedState from '../../hooks/usePersistedState'

import wallet from '../../assets/wallet.svg'
import calendarTime from '../../assets/calendar-time.svg'
import InputRange from '../../components/InputRange'
import { formatValue } from '../../utils/formatValues'
import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
import InputHidden from '../../components/InputHidden'
import ColoredBox from '../../components/ColoredBox'
import Button from '../../components/Button'
import Logo from '../../components/Logo'

import {
  UserData,
  Participant,
  ParticipantDetails,
} from '../../utils/interfaces'
import calculaIdade from '../../utils/calculaIdade'
import validaCPF from '../../utils/validaCPF'

const NovoParticipante: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails] = usePersistedState<ParticipantDetails>(
    'userDetails',
    {} as ParticipantDetails,
  )

  const [saveAndAddNew, setSaveAndAddNew] = useState(false)

  const [totalRentability] = usePersistedState('totalRentability', 0)

  const [tipoBenef, setTipoBenef] = useState('1')
  const [tipoVinculo, setTipoVinculo] = useState('')
  const [vlrProporcao, setVlrProporcao] = useState(1)

  /* const rentabilityPerYear = useMemo(
    () => (totalRentability - 100) / userDetails.years,
    [totalRentability, userDetails],
  ) */

  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )

  const [thisParticipantData, setThisParticipantData] = useState<UserData>(
    {} as UserData,
  )

  const [thisParticipant, setThisParticipant] = useState<Participant>(
    {} as Participant,
  )

  const [thisParticipantDetails, setThisParticipantDetails] = useState<
    ParticipantDetails
  >({} as ParticipantDetails)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const location = useLocation()

  const [contribValue, setContribValue] = useState(0)
  const [contribTime, setContribTime] = useState(0)

  /* const changeFormContribValueAndTime = useCallback(
    (value, time) => {
      const fieldV = formRef.current?.getFieldRef('contribution')
      const fieldT = formRef.current?.getFieldRef('years')

      fieldV.value = value
      fieldT.value = time

      setThisParticipantDetails(() => ({
        ...thisParticipantDetails,
        contribution: value,
        years: time,
      }))
    },
    [setThisParticipantDetails, thisParticipantDetails],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => changeFormContribValueAndTime(contribValue, contribTime), [
    contribValue,
    contribTime,
  ])

  useEffect(() => {
    const yearInvested = 12 * contribValue

    const totalInvested = yearInvested * contribTime

    const totalBalance =
      (totalInvested * (rentabilityPerYear * contribTime + 100)) / 100

    setThisParticipant({ ...thisParticipant, totalInvested, totalBalance })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contribValue, contribTime, rentabilityPerYear]) */

  const toggleAuthorizePayment = useCallback(() => {
    setThisParticipantDetails({
      ...thisParticipantDetails,
      authorizePayment: !thisParticipantDetails.authorizePayment,
    })
  }, [thisParticipantDetails])

  const setContribTimeAndValue = useCallback(() => {
    const fieldV = formRef.current?.getFieldRef('contribution')
    const fieldT = formRef.current?.getFieldRef('years')

    fieldV.value = contribValue
    fieldT.value = contribTime
  }, [contribTime, contribValue])

  const handleAddNovo = useCallback(() => {
    setSaveAndAddNew(true)
    const field = formRef.current?.getFieldRef('addNew')
    field.value = true
    setContribTimeAndValue()
    formRef.current?.submitForm()
  }, [setContribTimeAndValue])

  const handleJustSave = useCallback(() => {
    setSaveAndAddNew(false)
    const field = formRef.current?.getFieldRef('addNew')
    field.value = false
    setContribTimeAndValue()
    formRef.current?.submitForm()
  }, [setContribTimeAndValue])

  const handleSubmit = useCallback(
    async formData => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(3, 'Digite o nome completo'),
          birthdate: Yup.string()
            .required('Campo obrigatório')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(formData.birthdate.split('/').reverse().join('-')) ||
                formData.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                moment(
                  formData.birthdate.split('/').reverse().join('-'),
                ).isValid() || formData.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                calculaIdade(
                  formData.birthdate.split('/').reverse().join('-'),
                ) <= 115 || formData.birthdate === '',
            ),
          cpf: Yup.string().test(
            '',
            'CPF inválido',
            () =>
              validaCPF(formData.cpf.replaceAll('.', '').replace('-', '')) ||
              formData.cpf === '',
          ),
        })

        await schema.validate(formData, { abortEarly: false })

        setThisParticipant({
          ...thisParticipant,
          data: {
            ...thisParticipantData,
            name: formData.name,
            cpf: formData.cpf,
            birthdate: formData.birthdate,
          },
          details: {
            ...thisParticipantDetails,
            // email: formData.email,
            tipoBen: tipoBenef,
            tipVinculo: tipoVinculo,
            proporcao: vlrProporcao,
            // contribution: formData.contribution,
            //  years: formData.years,

            // bestDayPayment: formData.bestDayPayment,
            // paymentType: formData.paymentType,
          },
        })

        setParticipants([
          ...participants,
          {
            ...thisParticipant,
            data: {
              ...thisParticipantData,
              name: formData.name,
              cpf: formData.cpf,
              birthdate: formData.birthdate,
            },
            details: {
              ...thisParticipantDetails,
              // email: formData.email,
              tipoBen: tipoBenef,
              tipVinculo: tipoVinculo,
              proporcao: vlrProporcao,
              // contribution: formData.contribution,
              // years: formData.years,
              // bestDayPayment: formData.bestDayPayment,
              // paymentType: formData.paymentType,
            },
          },
        ])
        if (formData.addNew === 'true') {
          history.push(`${location.pathname}`)
          formRef.current?.reset()
        } else {
          history.push('/participants-list')
        }
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      history,
      location.pathname,
      participants,
      setParticipants,
      thisParticipant,
      thisParticipantData,
      thisParticipantDetails,
      tipoBenef,
      tipoVinculo,
      vlrProporcao,
    ],
  )

  const aaa = participants.map(
    (participant, idx) => participant.details.proporcao,
  )
  let soma = 0
  for (let i = 0; i < aaa.length; i += 1) {
    soma += aaa[i]
  }

  const handleValidaProporcao = useCallback((prop, s) => {
    if (prop < 1 || prop > s) {
      const msg = `A proporção deve ser maior que 1 e menor que ${s}`
      alert(msg)
    } else {
      setVlrProporcao(prop)
    }
  }, [])

  return (
    <>
      <Container>
        <LogoContent>
          <div>
            <Logo />
          </div>
        </LogoContent>
        <br />
        {/* <Content>
          <BoxValue>
            <p>Qual valor mensal?</p>
            <InputRange
              name="contribution"
              initialValue={350}
              start={80.0}
              end={2000.0}
              step={1}
              prefix="R$"
              functionChange={setContribValue}
            />
            <div>
              <img src={wallet} alt="wallet" />
              <div>
                <p>Quero investir:</p>
                <h3>{formatValue(contribValue)}</h3>
                <small>Valor mensal recorrente</small>
              </div>
            </div>
          </BoxValue>

          <Divider />

          <BoxValue>
            <p>Por quantos anos deseja pagar até começar a receber?</p>
            <InputRange
              name="years"
              initialValue={15}
              start={3}
              end={50}
              step={1}
              sufix="Anos"
              functionChange={setContribTime}
            />
            <div>
              <img src={calendarTime} alt="wallet" />
              <div>
                <p>Pelo período:</p>
                <h3>{contribTime} Anos</h3>
                <small>Até começar a receber</small>
              </div>
            </div>
          </BoxValue>
        </Content>

        <article>
          <ColoredBox color="pink" gradientDirection="left">
            <span>Valor Investido</span>
            <h3>{formatValue(thisParticipant.totalInvested)}</h3>
            <small>Seu investimento no período</small>
          </ColoredBox>

          <ColoredBox color="green" gradientDirection="left">
            <span>Seu saldo projetado</span>
            <h3>{formatValue(thisParticipant.totalBalance)}</h3>
            <small>Seu investimento + rentabilidade</small>
          </ColoredBox>
        </article> */}

        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: thisParticipantData.name,
              cpf: thisParticipantData.cpf,
              // email: thisParticipantDetails.email,
              tipoBen: thisParticipantDetails.tipoBen,
              tipVinculo: thisParticipantDetails.tipVinculo,
              birthdate: thisParticipantDetails.birthdate,
              proporcao: thisParticipantDetails.proporcao,
              // paymentType: thisParticipantDetails.paymentType,
              // bestDayPayment: thisParticipantDetails.bestDayPayment,
            }}
          >
            <InputHidden name="addNew" type="hidden" />
            <InputHidden name="contribution" type="hidden" />
            <InputHidden name="years" type="hidden" />

            <RadioButton>
              <label>Tipo de Benefíciario</label>
              <div>
                <div>
                  <BtnContato
                    type="button"
                    isActive={tipoBenef === '1'}
                    onClick={() => setTipoBenef('1')}
                  >
                    Indicado
                  </BtnContato>
                </div>
                <div>
                  <BtnContato
                    type="button"
                    isActive={tipoBenef === '2'}
                    onClick={() => setTipoBenef('2')}
                  >
                    Legal
                  </BtnContato>
                </div>
                {/* <div>
                  <BtnContato
                    type="button"
                    isActive={tipoBenef === '3'}
                    onClick={() => setTipoBenef('3')}
                  >
                    Ambos
                  </BtnContato>
                </div> */}
              </div>
            </RadioButton>

            {/* <RadioButton>
              <label>Tipo de Benefíciario</label>
              <div>
                <div>
                  <input
                    type="radio"
                    name="tipoBen"
                    value="L"
                    checked={tipoBenef === 'L'}
                    onChange={e => setTipoBenef(e.target.value)}
                  />
                  <label>Legal</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="tipoBen"
                    value="I"
                    checked={tipoBenef === 'I'}
                    onChange={e => setTipoBenef(e.target.value)}
                  />
                  <label>Indicado</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="tipoBen"
                    value="A"
                    checked={tipoBenef === 'A'}
                    onChange={e => setTipoBenef(e.target.value)}
                  />
                  <label>Ambos</label>
                </div>
              </div>
            </RadioButton>
            {tipoBenef === '3' || tipoBenef === '1' ? ( */}
            <DropDown>
              <div>
                <label>Tipo de Vínculo</label>
                <select
                  name="tipoVinculo"
                  onChange={e => setTipoVinculo(e.target.value)}
                >
                  <option value=""> </option>
                  <option value="O">Outro</option>
                  <option value="C">Cônjuge</option>
                  <option value="F">
                    Filhos e/ou enteados solteiros até 21 anos de idade ou 24
                    anos, se universitário
                  </option>
                </select>
              </div>
            </DropDown>
            {/*  ) : (
              <></>
          )} */}
            <Input placeholder="Nome completo" name="name" icon={FiUser} />
            {/* <Input
              icon={FiMail}
              name="email"
              type="email"
              placeholder="E-mail"
            /> */}
            <Input
              icon={FiCalendar}
              name="birthdate"
              type="text"
              mask="date"
              placeholder="Data de nascimento"
            />
            <Input
              placeholder="CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
              required
            />

            <div>
              <Input
                placeholder="Proporção"
                id="PercContribSup"
                icon={FiPercent}
                value={vlrProporcao}
                name="proporcao"
                type="number"
                min="1"
                max={soma === 0 ? 100 : 100 - soma}
                onChange={e =>
                  handleValidaProporcao(
                    e.target.valueAsNumber,
                    soma === 0 ? 100 : 100 - soma,
                  )
                }
              />
            </div>

            {/* <InputSelect
              name="paymentType"
              options={[
                { label: 'Boleto', value: 'boleto' },
                { label: 'Cartão de crédito', value: 'credit' },
                { label: 'Débito em conta', value: 'debito' },
              ]}
              placeholder="Forma de pagamento"
              icon={BiDollar}
            />
            <Input
              placeholder="Melhor dia para pagamento"
              min={1}
              max={30}
              name="bestDayPayment"
              icon={FiCalendar}
              type="number"
              required
              prefix="Dia"
            /> */}
          </Form>

          {/* <DivTerms>
            <span>
              Autorizo a cobrança recorrente no valor informado acima.
            </span>
            <Switch
              onChange={toggleAuthorizePayment}
              checked={thisParticipant.authorizePayment}
              checkedIcon={false}
              uncheckedIcon={false}
              height={22}
              width={44}
              handleDiameter={18}
              offColor="#EB6A9F"
              offHandleColor="#fff"
              onHandleColor="#fff"
              onColor="#31D19E"
            />
          </DivTerms> */}
          <Button
            // disabled={!thisParticipant.authorizePayment}
            type="button"
            fontSize="small"
            onClick={handleAddNovo}
          >
            Adicionar mais um participante
          </Button>
        </Content>
        <Button
          type="button"
          fontSize="normal"
          color="green"
          // width="large"
          // disabled={!thisParticipant.authorizePayment}
          onClick={handleJustSave}
        >
          <FiCheck size={45} />
          <span>Pronto! Concluir</span>
        </Button>

        {participants.length > 0 ? (
          <Button
            type="button"
            fontSize="normal"
            color="white"
            onClick={() => history.push('/participants-list')}
          >
            Ver participantes
          </Button>
        ) : (
          <Button
            // disabled={!thisParticipant.authorizePayment}
            type="button"
            fontSize="normal"
            color="pink"
            onClick={() => history.push('/fatca')}
          >
            <span>Não tenho beneficiários</span>
          </Button>
        )}

        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default NovoParticipante
