import styled from 'styled-components';
import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  height:100%;
  width:100%;
  margin:0 auto;
  padding: 20px 15px;
  justify-content: space-evenly;

  @media screen and (min-width: 766px){

  height:100%;
  width:100%;
  }
`

export const TitleContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  // color: #04679F;
  margin-bottom: 10px;
  font-size: 20px;
  width: 400px;
/*
  @media screen and (min-width: 766px) {
    width: 420px;
  }*/

`

export const Content = styled.main`

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
  padding: 0 15px 15px;

  > form {
    width: 100%;
  }

  > small {
    color: #8a8a8a;
    font-size:11px;
    text-align: center;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 766px){
    > small {
      font-size: 12px;
    }
  }
`

export const BoxValue = styled.div`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 12px 14px rgba(209,212,226, 0.4);
  padding: 15px;
  background:#FFF;

  margin: 25px 0;

  > p {
    font-weight: bold;
    color: #8a8a8a;
    margin-bottom: 12px;
  }

  > span {
    color: #ca6048;
    font-size: 12px;
    font: 'Verdana';
  }

  > div:last-child {
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    > div {
      width: 105px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-left: 10px;
      > p {
        color: #1c1c1c;
        font-size: 12px;
        margin:0;
      }
      > input {
        color: #31D19E;
        font-size: 18px;
        font-weight: bold;
        outline:0;
        width:100%;
        border: 0px;
        text-align: right;
      }
      > small {
        color: #8a8a8a;
        font-size: 9px;
        width: 100%;
        text-align: center;
      }
    }
  }



  @media screen and (min-width: 766px){
    padding: 16px;
    margin: 30px 0px;

    > p {
      margin-bottom: 14px;
    }

    > div:last-child {
      margin-top: 14px;

      > div {
        width: 120px;

        > p {
          color: #1c1c1c;
          font-size: 13px;
          margin:0;
        }
        > h3 {
          color: #31D19E;
          font-size: 19px;
          font-weight: bold;
        }
        > small {
          color: #8a8a8a;
          font-size: 10px;
        }
      }
    }

  }

`
export const BtnVoltar = styled.button`
  border: none;
  background-color: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
  padding: 0px 3px 2px;
  color: ${({ theme }) => theme.colors.text};

  &:hover {

    border-bottom: 1px solid ${({ theme }) => theme.colors.text};

  }
`

export const LogoContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  // background-color: #FF8636;

/*
  @media screen and (min-width: 766px) {
    width: 500px;
  }*/

`


export const SliderContribuicao = withStyles({
  root: {
    color: '#777',
    height: 8,
    '&$disabled': {
      color: '#BDBDBD',
    },
  },
  disabled: {},
  thumb: {
   /* height: 18,
    width: 18,
    top: 9,
    */
    color: '#ED7D31',
    backgroundColor: '#ED7D31',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '&$disabled': {
      color: '#BDBDBD',
      backgroundColor: '#BDBDBD',
    },
  },
  active: {},
  valueLabel: {
    //left: 'calc(-50%)',
    top: -16,
    '& *': {
      background: 'transparent',
      color: '#000',
      fontFamily: ['Open Sans', 'sans-serif'],
    },
  },
})(Slider);


export const DropDown = styled.div`
  background: initial;
  border-radius: 4px;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  width: 100%;

  position:relative;

  border: 1px solid #D2D2D2;
  color: ${props => props.theme.colors.placeholder};

  margin-bottom: 12px;
  transition: .2s;

  > div {
    margin-top: 3px;
    margin-bottom: 3px;
    width: 100%;
    // font-size: 20px;
    // font: 'Verdana';
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    > span {
      //font-weight: bold;
      //margin-bottom: 12px;
      font-size: 13px;
    }

    > select {
      font-size: 14px;
      font-family: 'Karla','Roboto',sans-serif;
      color: #666360;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      border: 1px solid hsl(0,0%,80%);
      border-radius: 4px;
      min-height: 38px;
    }

    > div {
      display: flex;
      flex-direction:row;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 26px;
    }
  }
`
