import styled from 'styled-components';

export const Container = styled.img`
  width: 180px;
  margin-bottom: 25px;

  @media screen and (min-width: 766px) {
    width: 220px;
    margin-bottom: 35px;
  }
`;
