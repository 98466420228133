import styled, { css } from 'styled-components';




interface ContainerProps {
  color?: 'white' | 'green' | 'pink' | 'purple' | 'orange' | 'blue'
  gradientDirection?: 'left' | 'right'
}

const getGradientAndShadow =
  (color1: string, color2: string, direction: ContainerProps['gradientDirection'], shadowColor: string) => css`
  ${ direction === 'right' ? css`
    background-image: linear-gradient(60deg,${color1},${color2});
  ` : css`
    background-image: linear-gradient(60deg,${color2},${color1});
  `}
  box-shadow:0px 2px 4px ${shadowColor};

`

const colorTypesVariation = {
  green: {
    left: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#6DE381', '#31D19E', 'left', 'rgba(54, 211, 156,0.6)')}
    `,
    right: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#6DE381', '#31D19E', 'right', 'rgba(54, 211, 156,0.6)')}
    `
  },
  orange: {
    left: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#FDA10D', '#ffc456', 'left', '#B67102')}

    `,
    right: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#FDA10D', '#ffc456', 'right', '#B67102')}
    `
  },
  blue: {
    left: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#04679F', '#0797EB', 'left', 'rgba(4,103,159,0.6)')}

    `,
    right: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#04679F', '#04679F', 'right', 'rgba(4,103,159,0.6)')}
    `
  },
  pink: {
    left: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#FBADB4', '#EB6A9F', 'left', 'rgba(250, 168, 179,0.6)')}

    `,
    right: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#FBADB4', '#EB6A9F', 'right', 'rgba(250, 168, 179,0.6)')}
    `
  },
  purple: {
    left: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#9C9EFF', '#7A71EF', 'left', 'rgb(209, 212, 226)')}

    `,
    right: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#9C9EFF', '#7A71EF', 'right', 'rgb(209, 212, 226)')}
    `
  },
  white: {
    left: css`
      color: ${({ theme }) => theme.colors.text};
      background-color: #FFFFFF;
      box-shadow:0px 2px 4px rgba(198,200,207,0.29);
      border: 1px solid #EAEAEA;
    `,
    right: css`
      color: ${({ theme }) => theme.colors.text};
      background-color: #FFFFFF;
      box-shadow:0px 2px 4px rgba(198,200,207,0.29);
      border: 1px solid #EAEAEA;
    `
  },

}



export const Container = styled.div<ContainerProps>`
  ${props => colorTypesVariation[props.color || 'white'][props.gradientDirection || 'right']}
  position:sticky;
  top:0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 50px;

  height: 60px;
  margin-bottom: 20px;

  strong {
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    width: 100%;
  }


  @media screen and (min-width: 766px){

    strong {
      font-size: 18px;
      line-height: 21px;
      width: 100%;
    }
  }


  @media screen and (min-width: 1024px){

  strong {
    font-size: 20px;
    line-height: 24px;
    width: 100%;
  }
}
teste-a {

}
`;


export const Logo = styled.div<ContainerProps>`
  position: static;
  width: 200px;

  img {
    height: 50px;
  }
`;
