import styled from 'styled-components';
import { shade, tint } from 'polished'
import { Link } from 'react-router-dom';


export const Container = styled.div`

  margin-top: 0px;

  display:flex;
  flex-direction: column;
  margin:0 auto;

  padding-top: 25px;

  max-width: 420px;

  @media screen and (min-width:  766px){
    max-width: 500px;
  }

`;


export const Content = styled.div`
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;

  > strong {
    font-size: 14px;
    text-align: center;
    display: block;
    color: #8A8A8A;
    margin-bottom:16px;
  }
  >form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > p {
    font-size: 14px;
    color: #8A8A8A;
    text-align: center;
    margin-bottom:16px;
  }

  @media screen and (min-width: 766px){
    > strong {
      font-size: 16px;
    }
    >p {
      font-size: 16px;
      margin-bottom: 18px;
    }
  }
`

export const Timer = styled.span`
  font-size: 12px;
  color: #8A8A8A;
  margin-bottom:4px;

  @media screen and (min-width: 766px){

    font-size: 14px;
    margin-bottom: 6px;
  }
`

export const LinkBack = styled(Link)`
  text-decoration: none;
  border: none;
  background-color: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  color: #8A8A8A;
  font-size: 12px;

  &:hover {
    color: ${ ({ theme }) => theme.colors.text};
  }

  @media screen and (min-width: 766px){

    font-size: 14px;
    padding: 8px;
  }
`

export const ContentBt = styled.div`
  padding: 0px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex:1;

  > strong {
    font-size: 14px;
    text-align: center;
    display: block;
    color: #8A8A8A;
    margin-bottom:16px;
  }
  >form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > p {
    font-size: 14px;
    color: #8A8A8A;
    text-align: center;
    margin-bottom:16px;
  }

  @media screen and (min-width: 766px){
    > strong {
      font-size: 16px;
    }
    >p {
      font-size: 16px;
      margin-bottom: 18px;
    }
  }
`

export const LogoContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  // background-color: #FF8636;

/*
  @media screen and (min-width: 766px) {
    width: 500px;
  }*/

`
