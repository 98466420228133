import React, { useRef, useCallback, useEffect, useState } from 'react'

import {
  FiMail,
  FiCalendar,
  // FiClock,
  FiSmartphone,
  FiPhone,
  FiUser,
  // FiDollarSign,
} from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'

import * as Yup from 'yup'

import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'

import { useHistory } from 'react-router-dom'
// import Slider from '@material-ui/core/Slider'

import moment from 'moment'
import { BiCake } from 'react-icons/bi'
import {
  Container,
  Content,
  LogoContent,
  TitleContent,
  /* BoxValue,
  BtnVoltar,
  SliderContribuicao, */
  DropDown,
} from './styles'

import Header from '../../components/Header'
import Logo from '../../components/Logo'
import usePersistedState from '../../hooks/usePersistedState'
import Input from '../../components/Input'
import InputSelect from '../../components/InputSelect'
// import InputRange from '../../components/InputRange'

// import wallet from '../../assets/wallet.svg'
import Button from '../../components/Button'
import InputHidden from '../../components/InputHidden'
// import formatValue from '../../utils/formatValue'
import getValidationErrors from '../../utils/getValidationErrors'

import { UserData, UserDetails } from '../../utils/interfaces'
import calculaIdade from '../../utils/calculaIdade'
import validaCPF from '../../utils/validaCPF'

const PersonalInfos: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [patrocinadora, setPatrocinadora] = useState({
    label:
      userData.dcrPatrocinadora === undefined ? '' : userData.dcrPatrocinadora,
    value: userData.patrocinadora === undefined ? '' : userData.patrocinadora,
  })

  const [patroc, setPatroc] = useState(userData.patrocinadora)
  // console.log(userData)
  // const [URPIII] = useState(4694.44)
  // const [tipo, setTipo] = useState('P')

  const history = useHistory()

  const formRef = useRef<FormHandles>(null)

  const handleSubmit = useCallback(
    async (data: UserData) => {
      try {
        formRef.current?.setErrors({})
        /* console.log(data.phoneCel.substr(0, 4))
        console.log(data.phoneCel.substr(5, 1)) */
        // const dddFix = data.phoneFix.substr(1, 2)
        // const firstDigFix = data.phoneFix.substr(5, 1)
        const dddCel = data.phone.substr(1, 2)
        const firstDigCel = data.phone.substr(5, 1)

        if (dddCel === '00' || firstDigCel === '0') {
          alert('Telefone celular inválido')
          document.getElementById('phone')?.focus()
          // } else if (dddFix === '00' || firstDigFix === '0') {
          //   alert('Telefone fixo inválido')
          //   document.getElementById('phoneFix')?.focus()
          // } else if (
          //   patrocinadora.value === '' ||
          //   patrocinadora.value === undefined ||
          //   patrocinadora.value === null
          // ) {
          //   alert('Informe a patrocinadora.')
        } else {
          // eslint-disable-next-line no-useless-escape
          // const RegExPattern = /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02]) {6}[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/

          const schema = Yup.object().shape({
            cpf: Yup.string()
              .required('CPF obrigatório')
              .test(
                '',
                'CPF inválido',
                () =>
                  validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) ||
                  data.cpf === '',
              ),
            admission: Yup.date()
              .required('Data de admissão obrigatória')
              .test(
                '',
                'A data de admissão não pode ser maior que hoje.',
                () =>
                  moment() >
                    moment(data.admission.split('/').reverse().join('-')) ||
                  data.admission === '',
              )
              .test(
                '',
                'Data de admissão inválida',
                () =>
                  moment(
                    data.admission.split('/').reverse().join('-'),
                  ).isValid() || data.admission === '',
              ),
            name: Yup.string()
              .required('Seu nome é obrigatório.')
              .matches(/\s/g, 'Digite o nome completo')
              .min(3, 'Digite o nome completo'),
            birthdate: Yup.string()
              .required('Data de nascimento obrigatória')
              .test(
                '',
                'A data de nascimento não pode ser maior que hoje.',
                () =>
                  moment() >
                    moment(data.birthdate.split('/').reverse().join('-')) ||
                  data.birthdate === '',
              )
              .test(
                '',
                'Data de nascimento inválida',
                () =>
                  moment(
                    data.birthdate.split('/').reverse().join('-'),
                  ).isValid() || data.birthdate === '',
              )
              .test(
                '',
                'Data de nascimento inválida',
                () =>
                  calculaIdade(data.birthdate.split('/').reverse().join('-')) <=
                    115 || data.birthdate === '',
              ),
            // emailCorp: Yup.string()
            //   // .required('E-mail é obrigatório')
            //   .email('Digite um e-mail válido'),
            email: Yup.string()
              .required('E-mail pessoal é obrigatório')
              .email('Digite um e-mail válido'),
            // phoneFix: Yup.string()
            //   // .min(14, 'Deve ter ao menos 10 algarismos')
            //   .max(15, 'Telefone inválido'),
            phone: Yup.string()
              // .min(14, 'Deve ter ao menos 10 algarismos')
              .max(15, 'Telefone inválido')
              .min(14, 'Telefone inválido')
              .required('Celular é obrigatório'),
            /* contribution: Yup.number()
            .min(0)
            .max(2000)
            .required('Selecione o valor da contribuição'), */
            patrocinadora: Yup.string().required('Informe a patrocinadora'),
          })

          await schema.validate(data, { abortEarly: false })

          const aa = data.birthdate

          const dia = aa.split('/')[0]
          const mes = aa.split('/')[1]
          const ano = aa.split('/')[2]

          const dataForm = `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(
            -2,
          )}`
          // setUserDetails(data)
          // setUserData(data)
          setUserData({
            ...userData,
            cpf: data.cpf,
            admission: data.admission,
            name: data.name,
            birthdate: dataForm,
            // emailCorp: data.emailCorp,
            email: data.email,
            // phoneFix: data.phoneFix,
            phone: data.phone,
            patrocinadora: patrocinadora.value,
            dcrPatrocinadora: patrocinadora.label,
          })

          history.push('/contribuition')
        }
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [patrocinadora, setUserData, userData, history],
  )

  const handleChangePatrocinadora = useCallback(e => {
    const t = e
    setPatrocinadora(t)
  }, [])

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])
  /*
  const [salarioValue, setSalarioValue] = useState(0)

  const changeFormContrib = useCallback(
    val => {
      const field = formRef.current?.getFieldRef('contribution')

      field.value = val

      setUserDetails(() => ({ ...userDetails, contribution: val }))
    },
    [setUserDetails, userDetails],
  )

  const mudarSalario = useCallback(
    valor => {
      if (isNaN(valor)) {
        setSalarioValue(0)
      } else {
        setSalarioValue(valor)
      }
    },

    [setSalarioValue],
  )

  const [txtValor, setTxtValor] = useState('')
  const [vis1, setVis1] = useState(false)

  const handleCalculaContribuicao = useCallback(() => {
    if (salarioValue !== null && salarioValue > 0) {
      const pesoUrp = salarioValue / URPIII
      if (pesoUrp > 0 && pesoUrp <= 1) {
        const valueC = (salarioValue * 1.5) / 100
        setTxtValor(`contribuição de 1,5%, valor: ${valueC.toFixed(2)}`)
        setVis1(false)
      } else if (pesoUrp > 1 && pesoUrp <= 1.65) {
        const valueC = (salarioValue * 4) / 100
        setTxtValor(`contribuição de 4%, valor: ${valueC.toFixed(2)}`)
        setVis1(false)
      } else {
        setVis1(true)
      }
    }
  }, [URPIII, salarioValue])

  const handleMudaContrib = useCallback(
    valor => {
      const valueC = (salarioValue * valor) / 100
      setTxtValor(`contribuição de ${valor}%, valor: ${valueC.toFixed(2)}`)
    },

    [salarioValue],
  )

  const [vis2, setVis2] = useState(false)


  const validarContrib = useCallback(
    valor => {
      if (valor < 0 || valor > 2000) {
        alert('O valor da contribuição deve estar entre 0 e 2000')
        document.getElementById('contribution')?.focus()
      } else {
        setContribValue(valor)
      }
    },
    [setContribValue, contribValue],
  )

  useEffect(() => {
    changeFormContrib(salarioValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salarioValue]) */
  const [celState, setCelState] = useState('')
  const handleTeste = useCallback(
    e => {
      if (e.length === 1 && e === '0') {
        setCelState('')
      } else {
        setCelState(celState + e)
      }
    },
    [celState],
  )

  const [dtNasc, setDtNasc] = useState('')
  const handleOnKeyDown = useCallback(e => {
    if (e.which === 8) {
      let val = e.target.value
      if (val.length === 3 || val.length === 6) {
        val = val.slice(0, val.length - 1)
        setDtNasc(val)
      }
    }
  }, [])
  const handleOnChange = useCallback(
    e => {
      let val = e.target.value
      if (val.length === 2) {
        val += '/'
      } else if (val.length === 5) {
        val += '/'
      } else if (val.length > 10) {
        val = dtNasc
      }
      setDtNasc(val)
    },
    [dtNasc, setDtNasc],
  )
  return (
    <>
      <Container>
        <LogoContent>
          <div>
            <Logo />
          </div>
        </LogoContent>
        <TitleContent>
          <div>
            <strong>Informações Cadastrais</strong>
          </div>
        </TitleContent>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: userData.name,
              cpf: userData.cpf,
              birthdate:
                userData.birthdate === undefined
                  ? ''
                  : userData.birthdate.split('-').reverse().join('/'),
              admission: userData.admission,
              emailCorp: userData.emailCorp,
              email: userData.email,
              phoneFix: userData.phoneFix,
              phone: userData.phone,
              patrocinadora: userData.patrocinadora,
            }}
          >
            <InputHidden name="contribution" type="hidden" />
            <Input
              placeholder="CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
              disabled
            />
            <Input
              icon={FiCalendar}
              name="admission"
              type="date"
              placeholder="Data de admissão"
              min="1930-01-01"
              max={new Date().toISOString().split('T')[0]}
              disabled
            />
            <Input name="name" placeholder="Nome Completo" icon={FiUser} />
            {/* <Input
              icon={FiCalendar}
              name="birthdate"
              value={dtNasc}
              // type="date"
              placeholder="Data de nascimento"
              min="1930-01-01"
              max={new Date().toISOString().split('T')[0]}
              onChange={e => handleOnChange(e)}
              onKeyDown={e => handleOnKeyDown(e)}
            /> */}
            <Input
              icon={BiCake}
              name="birthdate"
              placeholder="Data de nascimento"
            />
            {/* <Input
              icon={FiMail}
              name="emailCorp"
              type="email"
              placeholder="E-mail corporativo"
            /> */}
            <Input
              icon={FiMail}
              name="email"
              type="email"
              placeholder="E-mail pessoal"
            />
            {/* <Input
              name="phoneFix"
              mask="phone"
              id="phoneFix"
              prefix="+55 | "
              placeholder="Telefone fixo DDD"
              icon={FiPhone}
            /> */}
            <Input
              name="phone"
              mask="phone"
              id="phone"
              prefix="+55 | "
              placeholder="Celular com DDD"
              icon={FiSmartphone}
              onChange={e => handleTeste(e.target.value)}
            />
            {/* <DropDown>
              <div>
                <span>Empresa em que trabalha</span>
                <select
                  name="patrocinadora"
                  onChange={e => setPatroc(e.target.value)}
                  value={patroc}
                >
                  <option value="">Selecione</option>
                  <option value="Sesi">Sesi</option>
                  <option value="Fiemg">Fiemg</option>
                  <option value="Senai">Senai</option>
                  <option value="Mais">Mais</option>
                  <option value="IEL">IEL</option>
                  <option value="CIEMG">CIEMG</option>
                </select>
              </div>
            </DropDown> */}
            <InputSelect
              name="patrocinadora"
              value={patrocinadora}
              options={[
                { label: 'SESI', value: 1 },
                { label: 'FIEMG', value: 2 },
                { label: 'IEL', value: 5 },
                { label: 'SENAI', value: 9 },
                { label: 'IER', value: 12 },
              ]}
              onChange={e => handleChangePatrocinadora(e)}
              placeholder="Patrocinadora"
            />
          </Form>
          <Button type="submit" color="green" onClick={handleClick}>
            Avançar
          </Button>
          {/* <BtnVoltar type="button" onClick={() => history.goBack()}>
            &lt; Anterior
          </BtnVoltar> */}
        </Content>
      </Container>
    </>
  )
}

export default PersonalInfos
