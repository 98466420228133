/* eslint-disable react/jsx-wrap-multilines */
import React, { useRef, useCallback, useState, useEffect } from 'react'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'

import { Base64 } from 'js-base64'
// import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import InputSMSCode from '../../components/InputSMSCode'
import usePersistedState from '../../hooks/usePersistedState'
import api from '../../services/api'

import {
  Container,
  Content,
  Timer,
  LinkBack,
  ContentBt,
  LogoContent,
} from './styles'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Logo from '../../components/Logo'

import { UserData, ConfigData, ErroProps } from '../../utils/interfaces'

const ConfirmSMS: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [erroProps, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )

  const [sizeCode] = useState(4)
  const [timer, setTimer] = useState(60) // 120
  const [reSend, setReSend] = useState(false)
  const [Seguir, setSeguir] = useState(false)
  const [timerSeguir, setTimerSeguir] = useState(5) // 60
  const [open, setOpen] = useState(false)

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const contato = configData.tipoContato === 'S' ? 'SMS' : 'e-mail'

  const handleSubmit = useCallback(
    data => {
      if (data.codeSMS.length === sizeCode) {
        const parametros = Base64.encode(
          `{"token": "${configData.token}",
          "codigoValidador": "${data.codeSMS}",
          "cliente": 1001}`,
        )

        const parametrosFinal = Base64.encode(parametros)
        // history.push('/personal-infos')
        api
          .post(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => history.push('/personal-infos'))
          .catch(err => setOpen(true))
      }
    },
    [history, sizeCode, configData],
  )

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
      if (timerSeguir > 1) {
        setTimerSeguir(timerSeguir - 1)
      } else if (timerSeguir === 1) {
        setTimerSeguir(timerSeguir - 1)
        setSeguir(true)
      }
    }

    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer, timerSeguir])

  const handleReSend = useCallback(() => {
    const parametrosGet = Base64.encode(
      `{"versao":"${configData.tipo}",
        "plano": "${configData.plano}",
        "cliente":"${configData.codCliente}",
        "cpf":"${userData.cpf.replaceAll('.', '').replace('-', '')}",
        "email":"${userData.email === undefined ? '' : userData.email}",
        "telefone":"${
          userData.phone === undefined
            ? ''
            : `55${userData.phone
                .replace('(', '')
                .replace(') ', '')
                .replace('-', '')}`
        }",
        "admissao":"${userData.admission}",
        "envio":"${configData.tipoContato}"}`,
    )

    const parametrosFinalGet = Base64.encode(parametrosGet)

    api
      .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinalGet}`)
      .then(res => setConfigData({ ...configData, token: res.data.token }))
      .catch(res => {
        if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else {
          alert('Ops, algo deu errado. Tente novamente mais tarde.')
        }
      })

    history.push('/register/confirm-sms')

    setReSend(false)
    setTimer(60)
  }, [configData, history, setConfigData, setErroProps, userData])

  const handleSeguir = useCallback(() => {
    /* let dataTrat = userData.phone.replace('(', '')
    dataTrat = dataTrat.replace(')', '')
    dataTrat = dataTrat.replace(' ', '')
    dataTrat = dataTrat.replace('-', '')

    const parametros = Base64.encode(
      `{"telefone":"55${dataTrat}","cliente":"1001","sistema":"ADZ","envio":"${token}"}`,
    )

    const parametrosFinal = Base64.encode(parametros)

    api
      .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
      .then(res => history.push('/goal-selection'))
      .catch(err => console.log(err.err)) */

    setSeguir(true)

    history.push('/personal-infos')
  }, [history])

  return (
    <Container>
      <LogoContent>
        <div>
          <Logo />
        </div>
      </LogoContent>
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                window.location.reload(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Código incorreto!
        </Alert>
        <br />
      </Collapse>
      <Content>
        <strong>
          {`Digite aqui o código de verificação que acabamos de te enviar via ${contato}.`}
        </strong>
        <br />
        <br />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputSMSCode name="codeSMS" size={sizeCode} formRef={formRef} />
        </Form>
        <br />
        <br />
        <br />
        <p>
          Não está recebendo o código? Sem problemas!
          <br />
        </p>
        <ContentBt>
          <Content>
            <Timer>00:{timer < 10 ? `0${timer}` : timer}</Timer>
            <Button
              onClick={handleReSend}
              type="button"
              color="green"
              disabled={!reSend}
            >
              Reenviar
            </Button>
            <p>Clique em reenviar para receber um novo código</p>
            <LinkBack to="/register">Quero trocar a forma de contato</LinkBack>
          </Content>
          {/* <Content>
            <Timer>
              00:{timerSeguir < 10 ? `0${timerSeguir}` : timerSeguir}
            </Timer>
            <Button
              onClick={handleSeguir}
              type="button"
              color="blue"
              disabled={!Seguir}
            >
              Prosseguir
            </Button>
            <p>
              Clique em prosseguir para seguir com a adesão sem confirmar o
              código.
            </p>
          </Content> */}
        </ContentBt>
      </Content>
    </Container>
  )
}

export default ConfirmSMS
