import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  margin:0 auto;
  align-items: center;
  height:100%;
  width:100%;
  padding: 20px 15px;
  justify-content: space-evenly;

  article {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom:30px;
    padding: 0 10px;

    > div {
      > span {
        font-size: 12px;
      }

      > h3 {
        font-size: 16px;
        font-weight: bold;
        margin:4px 0px;
      }

      > small {
        font-size: 9px;
      }
    }
  }
  > a {
    color: #8a8a8a;
    text-decoration:none;
    font-size:12px;
    font-weight: bold;
    &:hover {
      color: #1c1c1c;
    }
    margin-bottom:16px;
  }

  @media screen and (min-width:766px){
    max-width: 500px;
  }
`;

export const FatcaContent = styled.main`
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
  }

  #Titulo {
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 20px;
  }

  .msg {
    margin: 15px 0;
    font-size: 15px;
    /* background: red; */
  }

  #Check{
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }

  #Check1{
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 5px;
    >label{
      margin-left: 5px;
    }
  }
  #Check2{
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 5px;
    >label{
      margin-left: 5px;
    }
  }
  #Check3{
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 5px;
    >label{
      margin-left: 5px;
    }
  }
  #Check4{
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 5px;
    >label{
      margin-left: 5px;
    }
  }
  #Check5{
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 5px;
    >label{
      margin-left: 5px;
    }
  }
  #Check6{
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 5px;
    >label{
      margin-left: 5px;
    }
  }
  #Check7{
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 5px;
    >label{
      margin-left: 5px;
    }
  }
  #Check8{
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 5px;
    >label{
      margin-left: 5px;
    }
  }
`;


export const Content = styled.main`

  position:relative;
  display: flex;
  width: 90vw;
  background:#FFF;
  box-shadow: 0px 12px 14px rgba(209,212,226,0.4);
  border-radius: 4px;
  padding: 15px;
  margin-bottom:40px;
  flex-direction: column;
  align-items: center;
  > form {
    width: 100%;
  }

  @media screen and (min-width: 766px){
    width: 70vw;
    padding: 16px;

    max-width: 550px;

  }

  @media screen and (min-width: 990px) {
    width: 45vw;
  }
`;

export const DivTerms = styled.div`

  height: 25px;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > span{
    flex: 1;
    font-size: 12px;
    margin-right: 15px;
  }
`

export const Divider = styled.div`
  display: block;
  width: 70%;
  min-width: 230px;
  height:1px;
    background-color: #ECEDF3;

    margin: 20px auto 22px;
`

export const DropDown = styled.div`
  width: 100%;

  > div{
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 20px;
    font: 'Verdana';
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    > label {
      font-weight: bold;
      margin-bottom: 12px;
      font-size: 20px;
    }

    >select {
      font-size: 14px;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    > div {
      display: flex;
      flex-direction:row;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 26px;
    }
  }
`

export const RadioButton = styled.div`
background: initial;
border-radius: 4px;
padding: 8px 0px 16px;
display: -webkit-box;
display: -ms-flexbox;
-ms-flex-align: center;
width: 100%;
// background-color:red;

color: ${props => props.theme.colors.placeholder};

margin-bottom: 12px;
transition: .2s;

> div{
margin-top: 3px;
margin-bottom: 3px;
//font-size: 20px;
//font: 'Verdana';
display: flex;
flex-direction:row;
//margin: 0 auto;
align-items: center;
justify-content: center;

> div {
  display: flex;
  flex-direction:row;
  //margin: 0 auto;
  //align-items: center;
  //justify-content: center;
  // margin-top: 12px;
  // margin-bottom: 26px;
  //margin-left: 10px;
  margin-right: 10px;
  align-items: center;
}

input {
// appearance: none;
color: ${ props => props.theme.colors.text};
flex: 0;
text-align:  right;
border: 0;
background: transparent;
margin-right: 5px;

&::placeholder {
  color: ${ props => props.theme.colors.placeholder};
}

& + span{
  margin-right: 0px;
  margin-left: 4px;
}
}

& + span{
  margin-right: 0px;
  margin-left: 4px;
}
}
svg {
margin-right: 12px;
}
 /* width: 100%;

  #Titulo{
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 20px;
  }

  > div{
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 20px;
    font: 'Verdana';
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-direction:row;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 26px;
    }
  }

  #Msg{
    width:100%;
    margin: 0 auto;
    margin-bottom:5px;
      > small{
        font-size: 14px;
        text-align: justify;
        margin: 0 auto;
      }
  }
*/

`

export const LogoContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;

/*
  @media screen and (min-width: 766px) {
    width: 500px;
  }*/

`

export const BoxValue = styled.div`
  width: 100%;


  > p {
    font-weight: bold;
    color: #8a8a8a;
    margin-bottom: 12px;
    font-size: 14px;
  }

  > span {
    color: #ca6048;
    font-size: 12px;
    font: 'Verdana';
  }

  > div:last-child {
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    > div {
      width: 105px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-left: 10px;
      > p {
        color: #1c1c1c;
        font-size: 12px;
        margin:0;
      }
      > input {
        color: #31D19E;
        font-size: 18px;
        font-weight: bold;
        outline:0;
        width:100%;
        border: 0px;
        text-align: right;
      }
      > small {
        color: #8a8a8a;
        font-size: 9px;
        width: 100%;
        text-align: center;
      }
    }
  }


  @media screen and (min-width: 766px){

    > p {
      margin-bottom: 14px;
    }

    > div:last-child {
      margin-top: 14px;

      > div {
        width: 120px;

        > p {
          color: #1c1c1c;
          font-size: 13px;
          margin:0;
        }
        > h3 {
          color: #31D19E;
          font-size: 19px;
          font-weight: bold;
        }
        > small {
          color: #8a8a8a;
          font-size: 10px;
        }
      }
    }

  }


`
export const TitleContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  // background-color: #D9DADD;
  // color: #04679F;
  margin-top: 20px;
  //margin-bottom: 20px;
  // font-size:20px;
/*
  @media screen and (min-width: 766px) {
    width: 500px;
  }*/

`

export const BtnVoltar = styled.button`
  border: none;
  background-color: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
  padding: 0px 3px 2px;
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

interface BtnContatoProps {
  isActive: boolean;
}
export const BtnContato = styled.button<BtnContatoProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background-color: ${props => props.isActive? '#3BD49A' : 'transparent'} ;
  color: ${props => props.isActive? '#fff' : '#000'} ;
  border-radius: 4px;

  &:focus {
    text-decoration: underline;
  }
`
