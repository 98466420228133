import React, { useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import { Container, Content, LogoContent } from './styles'

import Logo from '../../components/Logo'

import endImage from '../../assets/end-image.svg'
import endFacebook from '../../assets/end-facebook.svg'
import endInstagram from '../../assets/end-instagram.svg'
import endYoutube from '../../assets/end-youtube.svg'

const End: React.FC = () => {
  return (
    <Container>
      <LogoContent>
        <div>
          <Logo />
        </div>
      </LogoContent>
      <br />
      <Content>
        <strong>
          Pronto! Seu pedido de aquisição do Plano de Mais Previdência foi salvo
          com sucesso!
        </strong>
        <img src={endImage} alt="endImage" />
        <p>
          Agora, nossos especialistas irão conferir todos os detalhes para a
          conclusão da sua adesão.
        </p>
        <div>
          <small>
            Aguarde e enquanto isso, acompanhe os nossos perfis nas mídias
            sociais:{' '}
          </small>
          <div>
            <a
              href="https://www.facebook.com/maisprevidencia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={endFacebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/maisprevidencia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={endInstagram} alt="instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCUHHbIAg_sQ22-uzOEoSbiw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={endYoutube} alt="youtube" />
            </a>
          </div>
        </div>
        <strong>ACESSE O NOSSO SITE:</strong>
        <strong>
          <a href="https://maisprevidencia.com.br/">maisprevidencia.com.br</a>
        </strong>
      </Content>
      <footer>
        {/* <p>
          {userData.name.split(' ')[0]}, que tal realizar a adesão de mais algum
          familiar?
        </p>
        <Button
          type="button"
          color="green"
          width="large"
          onClick={handleNewParticipant}
        >
          Sim, eu quero!
        </Button> */}
      </footer>
    </Container>
  )
}

export default End
