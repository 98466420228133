import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  // align-items: center;
  padding: 20px 15px;
  justify-content: space-evenly;
  // min-height: 100vh;
  min-height: 100vh;
  height: 100%;
  margin:0 auto;

  max-width: 1000px;

  @media screen and (min-width: 766px) {
    max-width: 900px;
  }
`;


export const DivContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  // background-color: white;
/*
  @media screen and (min-width: 766px) {
    width: 500px;
  }*/

`

export const LogoContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  // background-color: #04679F;

/*
  @media screen and (min-width: 766px) {
    width: 500px;
  }*/

`

interface ContentProps {
  isActive: boolean;
}
export const Content = styled.div<ContentProps>`
  display: flex;/*${props => props.isActive ? 'flex' : 'none'};*/
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;

  flex-direction: column;
  align-items: center;

  height: 100%;
  width: ${props => props.isActive ? '100%' : '0px'};
  opacity: ${props => props.isActive ? 1 : 0};

  pointer-events: ${props => props.isActive ? 'initial' : 'none'};
  img {
    margin-bottom: 35px;
  }
  strong {
    font-size: 16px;
    margin-bottom: 15px;
    text-align: center;
    //color: #04679F;
  }

  > span {
    font-size: 15px;
    text-align: center;
  }

  a {
    &:hover {
      //color: #04679F;
    }
  }

  > div{
    height: 30px;
    margin-top: 5px;
    flex-direction: row;
    // width:100%;
    // display: center;
    align-items: center;
    justify-content: center;
    // background-color: #04679F;

    > span{
      // flex: 1;
      font-size: 14px;
      // margin-right: 15px;
    }
  }


  @media screen and (min-width: 766px) {
    max-width:90%;
    //height: 430px;
    padding-top:15px;

    img {
      margin: 38px;
    }

    strong {
      font-size: 18px;
    }
    > span {
      font-size: 17px;
    }
    > div {
      height: 30px;
      margin-top:22px;

      >span {
        font-size: 16px;
      }
    }
  }
`

export const Footer = styled.footer`

  margin-top: 25px;

  display:flex;
  flex-direction: column;

  align-items: center;
    height: 130px;

  @media screen and (min-width: 766px) {
    margin: 30px 0;
  }
`
export const BtnVoltar = styled.button`
  border: none;
  background-color: unset;
  // text-decoration: underline;
  // display: flex;
  justify-content: center;
  align-items: center;
  // border-bottom: 1px solid transparent;
  // padding: 0px 3px 2px;
  color: 'black';

  &:hover {

    border-bottom: 1px solid ${({ theme }) => theme.colors.text};

  }
`
