import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  margin:0 auto;
  align-items: center;
  height:100%;
  min-height:calc(100vh - 95px);
  max-width: 420px;
  padding-top:25px;

  article {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom:30px;
    padding: 0 10px;

    > div {
      > span {
        font-size: 12px;
      }

      > h3 {
        font-size: 16px;
        font-weight: bold;
        margin:4px 0px;
      }

      > small {
        font-size: 9px;
      }
    }
  }
  > a {
    color: #8a8a8a;
    text-decoration:none;
    font-size:12px;
    font-weight: bold;
    &:hover {
      color: #1c1c1c;
    }
    margin-bottom:16px;
  }

  @media screen and (min-width:766px){
    max-width: 500px;
  }
`;


export const Content = styled.main`

  position:relative;
  display: flex;
  width: 90vw;
  background:#FFF;
  box-shadow: 0px 12px 14px rgba(209,212,226,0.4);
  border-radius: 4px;
  padding: 15px;
  margin-bottom:40px;
  flex-direction: column;
  align-items: center;
  > form {
    width: 100%;
  }

  @media screen and (min-width: 766px){
    width: 70vw;
    padding: 16px;

    max-width: 550px;

  }

  @media screen and (min-width: 990px) {
    width: 45vw;
  }
`;

export const DivTerms = styled.div`

  height: 25px;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > span{
    flex: 1;
    font-size: 12px;
    margin-right: 15px;
  }
`

export const Divider = styled.div`
  display: block;
  width: 70%;
  min-width: 230px;
  height:1px;
    background-color: #ECEDF3;

    margin: 20px auto 22px;
`

export const BoxValue = styled.div`
  width: 100%;

  > p {
    font-weight: bold;
    color: #8a8a8a;
    margin-bottom: 12px;
    font-size: 14px;
  }

  > div:last-child {
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    > div {
      width: 105px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-left: 10px;

      > p {
        color: #1c1c1c;
        font-size: 12px;
        margin:0;
      }
      > h3 {
        color: #31D19E;
        font-size: 18px;
        font-weight: bold;
      }
      > small {
        color: #8a8a8a;
        font-size: 9px;
      }
    }
  }


  @media screen and (min-width: 766px){

    > p {
      margin-bottom: 14px;
    }

    > div:last-child {
      margin-top: 14px;

      > div {
        width: 120px;

        > p {
          color: #1c1c1c;
          font-size: 13px;
          margin:0;
        }
        > h3 {
          color: #31D19E;
          font-size: 19px;
          font-weight: bold;
        }
        > small {
          color: #8a8a8a;
          font-size: 10px;
        }
      }
    }

  }


`

export const LogoContent = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;

/*
  @media screen and (min-width: 766px) {
    width: 500px;
  }*/

`

export const DropDown = styled.div`
  background: initial;
  border-radius: 4px;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  width: 100%;

  position:relative;

  border: 1px solid #D2D2D2;
  color: ${props => props.theme.colors.placeholder};

  margin-bottom: 12px;
  transition: .2s;

  > div {
    margin-top: 3px;
    margin-bottom: 3px;
    // font-size: 20px;
    // font: 'Verdana';
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    > label {
      //font-weight: bold;
      //margin-bottom: 12px;
      font-size: 13px;
    }

    > select {
      font-size: 14px;
      font-family: 'Karla','Roboto',sans-serif;
      color: #666360;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      border: 1px solid hsl(0,0%,80%);
      border-radius: 4px;
      min-height: 38px;
    }

    > div {
      display: flex;
      flex-direction:row;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 26px;
    }
  }
`

export const RadioButton = styled.div`
  background: initial;
  border-radius: 4px;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  width: 100%;

  position:relative;

  border: 1px solid #D2D2D2;
  color: ${props => props.theme.colors.placeholder};

  margin-bottom: 12px;
  transition: .2s;

  > div{
    margin-top: 3px;
    margin-bottom: 3px;
    //font-size: 20px;
    //font: 'Verdana';
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-direction:row;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      // margin-top: 12px;
      // margin-bottom: 26px;
      margin-left: 10px;
      margin-right: 10px;
    }

    input {
    // appearance: none;
    color: ${ props => props.theme.colors.text};
    flex: 0;
    text-align:  right;
    border: 0;
    background: transparent;
    margin-right: 5px;

    &::placeholder {
      color: ${ props => props.theme.colors.placeholder};
    }

    & + span{
      margin-right: 0px;
      margin-left: 4px;
    }
  }

    & + span{
      margin-right: 0px;
      margin-left: 4px;
    }
  }
  svg {
    margin-right: 12px;
  }
`

export const BtnVoltar = styled.button`
  border: none;
  background-color: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
  padding: 0px 3px 2px;
  color: ${({ theme }) => theme.colors.text};

  &:hover {

    border-bottom: 1px solid ${({ theme }) => theme.colors.text};

  }
`

interface BtnContatoProps {
  isActive: boolean;
}
export const BtnContato = styled.button<BtnContatoProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background-color: ${props => props.isActive? '#3BD49A' : 'transparent'} ;
  color: ${props => props.isActive? '#fff' : '#000'} ;
  border-radius: 4px;
`
